export const financialDetailsData = {
    zh_CN: {
        occupation: [
            { value: 'Accountant / auditor ', label: '会计师／核数师' },
            { value: 'Architect', label: '建筑师' },
            { value: 'Artist/performer/producer/athlete', label: '艺人／表演者／监制／运动员' },
            { value: 'Business owner/partner/director/senior management', label: '企业主／合伙人／总监／高级管理层' },
            { value: 'Chef/server', label: '厨师／侍应员' },
            { value: 'Clerical/admin/secretarial', label: '文员／行政人员／秘书' },
            { value: 'Construction worker', label: '建造业工作者' },
            { value: 'Consultant', label: '顾问' },
            { value: 'Data processer /IT manager', label: '数据处理员／资讯科技经理' },
            { value: 'Domestic helper/cleaner', label: '家务助理／清洁员' },
            { value: 'Driver', label: '司机' },
            { value: 'Engineer ', label: '工程师' },
            { value: 'Farmer/fisherperson/forester', label: '农业／渔业／林业工作者' },
            { value: 'Financial Controller', label: '财务总监' },
            { value: 'Firefighter', label: '消防员' },
            { value: 'Flight attendant/airport ground crew/pilot', label: '空中服务员／机场地勤人员／飞机师' },
            { value: 'Immigration services officer', label: '入境事务主任' },
            { value: 'Inland revenue officer ', label: '税务主任' },
            { value: 'Insurance agent/brokers', label: '保险代理人／经纪' },
            { value: 'Investment / fund manager', label: '投资／基金经理' },
            { value: 'Lawyer/solicitor', label: '律师／事务律师' },
            { value: 'Marketing', label: '市场推广人员' },
            { value: 'Medical professional ', label: '医务专业人员' },
            { value: 'Military', label: '军事人员' },
            { value: 'Other licensed professional', label: '其他持牌专业人员' },
            { value: 'Other manual/ skilled worker ', label: '其他体力劳动／技术工作者' },
            { value: 'Other office worker/semi-professional', label: '其他办公室／半专业工作者' },
            { value: 'Other service worker (e.g. hospitality, personal services)', label: '其他服务工作者（如款待业及私人服务）' },
            { value: 'Police', label: '警察' },
            { value: 'Politician/ambassador/consul-general', label: '从政者／大使／总领事' },
            { value: 'Postal worker', label: '邮政服务人员' },
            { value: 'Prison/correctional services officer', label: '监狱／惩教服务人员' },
            { value: 'Property agent', label: '地产经纪' },
            { value: 'Salesperson', label: '销售员' },
            { value: 'Security guard', label: '保安' },
            { value: 'Senior government/judicial official', label: '高级政府／司法人员' },
            { value: 'Social worker', label: '社会工作者' },
            { value: 'Teacher/principal/lecturer/professor', label: '教师／校长／讲师／教授' },
            { value: 'Traffic warden', label: '交通督导员' },
        ],
        employmentRoleList: [
            { value: 'Business Owner', label: '企业主' },
            { value: 'Employee', label: '雇员' },
            { value: 'Key Controller', label: '主要管理人' },
            { value: 'Sole Trader', label: '独资经营者' },
        ],
        industryList: {
            'Accounting, book-keeping and auditing activities; tax consultancy': '会计、部记及核数事务；税务顾问',
            'Activities auxiliary to financial intermediation n.e.c., except to insurance and pension funding ': '金融中介公司辅助机构（不包括保险及退休金公司）',
            'Activities auxiliary to insurance and pension funding': '保险及退休金公司辅助机构',
            'Activities normally carried out by the public administration': '公共管理服务，政府',
            'Activities of travel agencies and tour operators; tourist assistance activities n.e.c.': '旅行社及导游',
            'Administration of financial markets, including the activities of stock exchanges and exchanges for commodity futures contracts': '金融市场管理公司-股票及商品期货',
            'Advertising': '广告',
            'Agricultural and animal husbandry service activities, except veterinary activities': '农务及家畜饲养行业（兽医除外）',
            'Architectural and engineering activities and related technical consultancy, n.e.c.': '建筑及工程事务及有关技术顾问服务',
            'Architectural consulting activities ': '建筑顾问事务',
            'Banks': '银行',
            'Bars': '酒吧',
            'Bath house, sauna & massage salons': '浴室、桑拿浴室及按摩院',
            'Botanical and zoological gardens and nature reserves activities': '动植物花园及自然保护行业',
            'Building and repairing of ships and boats': '船舰、舢板制造及修理',
            'Building completion': '建筑物完工',
            'Building installation n.e.c.': '建筑物装置工程',
            'Building installation, including the installation in buildings of all kinds of utilities ': '建筑物装置包括建筑物内所有用具设施',
            'Building of complete constructions or parts thereof; civil engineering, n.e.c.': '建筑物完工及部份建造工程、土木工程',
            'Building-cleaning and industrial-cleaning activities': '大厦清洁及工业清洁服务',
            'Business and employers organizations': '商业及雇主团体',
            'Business and management consultancy activities': '商业及管理顾问服务',
            'Cargo handling': '货物处理',
            'Casino and other types of gaming/gambling operation (SCC)': '赌场及娱乐场所行业',
            'Central banking': '中央银行',
            'Chinese herbalists/ medical practice including acupuncture': '中医及针灸',
            'Chinese Restaurants': '中式餐馆',
            'Chiropractice': '脊椎指压治疗／按摩',
            'Civil engineering including construction of highways, streets, bridges, tunnels, railways, sewerage systems, etc. ': '土木工程包括天桥、街道、桥梁、隧道、铁路及排水系统',
            'Clubs, karaoke & entertainment lounges': '夜总会、卡啦OK及同类娱乐场所',
            'Collection, purification and distribution of water': '食水收集、供应及过滤',
            'Compilation of data and provision of on-line access to proprietary databases': '数据编辑及联系至专有之数据库支持',
            'Courier activities other than national post activities': '速递不包括国际邮递服务',
            'Custom tailoring': '裁缝师',
            'Cutting, shaping and finishing of stone for use in construction, in cemeteries, on roads, as roofing and in other applications': '打磨、切割石头以供建筑、墓碑、铺路或盖屋顶',
            'Data processing ': '电子数据处理',
            'Database activities and on-line distribution of electronic content n.e.c.': '数据库活动及联线发放电子内容',
            'Dental practice': '牙医',
            'Deposit-taking companies': '存款公司附属金融机构',
            'Dramatic arts, music and other arts activities': '戏剧艺术、音乐及其他艺术行业',
            'Dressing and dyeing of fur; manufacture of articles of fur, n.e.c.': '皮草漂染及加工',
            'Dressing and dyeing of furskins': '皮革漂染及服饰加工',
            'Engineering and related technical consultancy activities ': '工程及有关技术顾问服务',
            'Export credit agencies': '出口信贷代理公司',
            'Extra-territorial organizations and bodies ': '不分地区组织团体／机构',
            'Extraction of crude petroleum and natural gas': '提炼石油和天然气',
            'Factoring institutions': '经销商',
            'Farming of animals and livestock': '饲养家畜',
            'Fast Food Shops': '快餐店',
            'Finance houses': '财务公司',
            'Financial leasing': '财务租贷公司',
            'Finishing of textiles by such operations as dyeing or printing': '纺织品漂染及印花',
            'Fishing, aquaculture and service activities incidental to fishing': '捕鱼业、养鱼业及有关业务',
            'Fixed telecommunications network services (FTNS) operators': '固定电信网络服务员',
            'Forestry, logging and related service activities': '森林管理、伐木业和有关业务',
            'Forging, pressing, stamping and roll-forming of metal; powder metallurgy': '炼造、压造及滚筒压铁；冶金术',
            'Freight forwarders': '货物运输',
            'Freight transport by road': '陆路货物运输',
            'Fund manager (as agent re named fund)': '基金经理 - 记名基金代理',
            'Fund manager (as agent re unnamed fund)': '基金经理 - 不记名基金代理',
            'Fund manager (as hedge fund)': '基金经理 -对冲基金',
            'Fund manager (as principle)': '基金经理',
            'Funeral and related activities': '殡仪及有关之服务',
            'General construction including new works, additions and alterations': '一般建设包新建造、加建及改造工程',
            'General secondary education': '一般中学教育',
            'Growing of crops combined with farming of animals (mixed farming)': '谷物生产／饲养家畜（混合耕作）',
            'Growing of crops, market gardening, horticulture': '谷物生产、种植蔬菜出售的行业',
            'Hairdressing and other beauty treatment': '理发及美容院',
            'Hardware consultancy': '电脑硬件顾问服务',
            'Higher education, including post-secondary sub-degree level education and university education': '高等 ／大学教育',
            'Holding of raw land for sale or future development': '持有未开发土地供销售或发展用途',
            'Hospitals': '医护行业',
            'Hotels; camping sites and other provision of short-stay accommodation ': '酒店、露营用地及公寓',
            'Hunting, trapping and game propagation including related service activities': '狩猎、捕捉及繁殖包括有关业务',
            'Inland water transport': '内陆水道运输',
            'Installation of illumination and signaling systems for roads, railways, airports, harbours, etc.': '道路、铁路、机场及码头照明及讯号系统装置',
            'Internet access provision': '互联网供货商',
            'Investigation and security activities ': '调查及保安事务',
            'Investment in securities (proprietary holdings)': '全东证券投资公司',
            'Knitting or crocheting of wearing apparel directly to shape ': '针织及钩针编织成衣制造',
            'Labour recruitment and provision of personnel ': '人事招募及人事支持行业',
            'Legal activities': '法律事务',
            'Library and archives activities': '图书馆及档案管理',
            'Licensed banks': '持牌银行附属金融机构',
            'Life insurance': '人寿保险',
            'Maintenance and repair of office, accounting and computing machinery': '办公室机器、电脑维修服务',
            'Manufacture and casting of precious metals including gold, silver, and metals of the platinum group, including production of clad precious metals': '铸造贵金属包括金、银、白金及镀金',
            'Manufacture and distribution of arms, other military products (SCC)': '军械及武器制造',
            'Manufacture of accumulators, primary cells and primary batteries': '蓄电池、电池及电蕊制造',
            'Manufacture of aircraft and spacecraft': '飞机及宇宙飞船制造',
            'Manufacture of alcoholic drinks including non-alcoholic beer and wine': '酒精饮品制造包括不含酒精啤酒及葡萄酒',
            'Manufacture of all kinds of clothing accessories ': '各类衣服配件',
            'Manufacture of apparatus for line telephony and line telegraphy': '有线电话及电报机制造',
            'Manufacture of articles of cork, straw and plaiting materials': '瓶塞、稻草编成之产品制造',
            'Manufacture of articles of fur such as rugs, unstuffed pouffes, etc.': '毛毯厚咕产品制造',
            'Manufacture of articles of leather or composition leather n.e.c.': '皮革及皮革合成品制造',
            'Manufacture of artificial flowers and trees': '人工花树制造',
            'Manufacture of bakery products': '面包制造',
            'Manufacture of basic chemicals ': '基本化学品制造',
            'Manufacture of basic iron and steel': '基本钢铁制造',
            'Manufacture of bicycles and invalid carriages': '单车及残疾人轮椅制造',
            "Manufacture of builders' carpentry and joinery, including manufacture of wooden goods primarily for the construction industry ": '建筑业大小木匠包括主要作建筑用途之木器产品制造',
            'Manufacture of carpets and rugs': '地毯制造',
            'Manufacture of cathode tubes or valves e.g. television picture tubes, television camera tubes, etc.': '阴极管、真空管制造',
            'Manufacture of cement, lime and plaster and articles thereof, such as boards, ready-mixed concrete, articles of asbestos-cement, etc.': '水泥、石灰、石膏制品如石板、混凝土及石绵产品制造',
            'Manufacture of cocoa, chocolate and sugar confectionery': '可可、朱古力及糖果糕点制造',
            'Manufacture of coke oven products ': '炭炉产品制造',
            'Manufacture of containers made of non-corrugated paper e.g. cartons, bags, etc.': '纸袋及纸箱纸盒制造',
            'Manufacture of cordage, rope, twine and netting': '船缆、麻绳及绳制造',
            'Manufacture of corrugated paper and corrugated boxes': '皱纹纸及纸箱制造',
            'Manufacture of cutlery, hand tools and general hardware': '刀剑利器制造、一般工具及铁器',
            'Manufacture of dairy products': '奶品制造',
            'Manufacture of electric lamps and lighting equipment': '电灯及照明用具制造',
            'Manufacture of electric motors, generators and transformers ': '电动摩打、发电机及变压器制造',
            'Manufacture of electricity distribution and control apparatus such as switches, fuses, lightning arresters, voltage limiters, surge suppressors, plugs, junction boxes, etc': '输电及控制器具如电掣、保险丝、避雷器、电压计、漏电断路掣、插头及接合器等制造',
            'Manufacture of electronic components n.e.c. e.g. diodes, transistors and similar semi-conductor devices, electrical capacitors or condensers, resistors, LCD displays, etc.': '电子产品如两极真空管、晶体管、半导体、电容器、电阻及液晶体显示器等制造',
            'Manufacture of electronic games e.g. handheld electronic games, video games, etc., and toy musical instruments': '电子游戏例如手游戏机、影视游戏及音乐玩具器材等制造',
            'Manufacture of electronic toys, e.g. radio-controlled/ infra-red controlled toy vehicles, educational toys, etc.': '电子玩具例如无线或红外线摇控玩具车及教育玩具等制造',
            'Manufacture of finished plastic products ': '塑料完成品制造',
            'Manufacture of footwear – leather uppers': '皮鞋制造-鞋面',
            'Manufacture of footwear – rubber or plastic uppers': '皮鞋制造-橡胶或塑料鞋面',
            'Manufacture of footwear – textile uppers': '皮鞋制造-布面',
            'Manufacture of footwear, n.e.c.': '皮鞋制造',
            'Manufacture of furniture, including mattresses': '家俬制造包括褥垫',
            'Manufacture of games and toys, n.e.c.': '游戏及玩具制造',
            'Manufacture of gas; distribution of gaseous fuels through mains': '气体燃料制造及供货商',
            'Manufacture of general purpose machinery and parts thereof': '一般用途之机械设置及零件制造',
            'Manufacture of glass and glass products': '玻璃及玻璃产品制造',
            'Manufacture of grain mill products, starches and starch products, and prepared animal feeds': '制造碾磨谷粒、淀粉和动物饲料',
            'Manufacture of hosiery, including socks, tights and panty hose': '针织品、袜、紧身衬衣及裤袜制造',
            'Manufacture of household appliances n.e.c. ': '家庭用器具制造',
            'Manufacture of instruments and appliances for measuring, checking, testing, navigating and other purposes, ': '量度、检验、测试及操纵仪器及装置制造',
            'Manufacture of insulated wire and cable': '绝缘电线及电缆制造',
            'Manufacture of integrated circuits': '集成电路版制造',
            'Manufacture of jewellery and related articles': '珠宝及附属商品制造',
            'Manufacture of knitted or crocheted articles n.e.c.': '机织及钩针编织品制造',
            'Manufacture of knitted or crocheted fabrics': '针织及钩针编织制造',
            'Manufacture of luggage, handbags and the like ': '手袋及行李箱制造',
            'Manufacture of macaroni, noodles, couscous and similar farinaceous products': '通心粉、面及面粉类产品制造',
            'Manufacture of machine-tools and parts and accessories': '机器工具及零件及配件',
            'Manufacture of machinery and equipment n.e.c.': '机械及用具制造',
            'Manufacture of machinery for textile, apparel and leather production': '纺织机、缝纫机及制皮机器',
            'Manufacture of man-made fibres ': '人造纤维制造',
            'Manufacture of medical and surgical equipment and orthopedic appliances': '医疗、外科手术及整形外科器材制造',
            'Manufacture of metal structures, including bridges and bridge parts, towers, masts and columns, doors and windows and their frames, games, etc. ': '铁制成品如铁桥及其零件、塔、柱、圆柱、门窗及框架、比赛设备等',
            'Manufacture of metal tanks, reservoirs and containers of types normally installed as fixtures ': '固定装置之铁槽、贮水池或贮水箱等制造',
            'Manufacture of metal toys': '金属玩具制造',
            'Manufacture of motor vehicles': '汽车制造',
            'Manufacture of motorcycles': '摩托车制造',
            'Manufacture of musical instruments': '音乐器材制造',
            'Manufacture of non-structural ceramic products including articles of porcelain or china, such as tableware and sanitary fixtures': '陶瓷及磁器制品如餐具或厕所设备制造',
            'Manufacture of nuclear reactors and steam generators, except central heating hot water boilers': '核反应堆、蒸气机（中央热水炉除外）制造',
            'Manufacture of office, accounting and computing machinery including typewriters, photo-copying apparatus, data-processing machines, etc': '办公室使用的机器如打字机、复印机及电脑等制造',
            'Manufacture of optical instruments and photographic equipment e.g. optical frames, cameras, projectors, etc.': '光学仪器及摄影装置例如光学摄影机及放影机等制造',
            'Manufacture of other articles of paper and paperboard ': '其他纸张及卡纸制成品',
            'Manufacture of other chemical products n.e.c. ': '其他化学品制造',
            'Manufacture of other electrical equipment n.e.c.': '其他电器用具制造',
            'Manufacture of other fabricated metal products n.e.c.': '伪造金属产品制造',
            'Manufacture of other food products n.e.c. ': '其他食品制造',
            'Manufacture of other non-metallic mineral products n.e.c.': '非金属矿产品制造',
            'Manufacture of other products of wood': '其他木器制造',
            'Manufacture of other rubber products ': '其他橡胶产品制造',
            'Manufacture of other special purpose machinery chiefly employed by a particular industry or by a number of related industries': '特别用途之机器制造',
            'Manufacture of other textiles n.e.c.': '其他纺织品制造业',
            'Manufacture of other transport equipment n.e.c.': '其他交通工具制造',
            'Manufacture of outerwear made of knitted or crocheted fabrics': '机织及钩针编织外衣制造',
            'Manufacture of outerwear made of woven fabrics': '纺织外衣制造',
            'Manufacture of paints, varnishes and similar coatings, printing ink and mastics': '油漆、光漆，涂层、油墨及灰泥制造',
            'Manufacture of paper or paperboard in bulk, in rolls or sheets ': '普通纸及卡纸业',
            'Manufacture of parts and accessories for motor vehicles, including engines, bodies (coachwork) and trailers ': '汽车零件及附属用品包括引擎、车身及拖架等制造',
            'Manufacture of parts of footwear': '皮鞋配件制造',
            'Manufacture of pesticides and other agro-chemical products ': '杀虫剂、农药制造',
            'Manufacture of pharmaceuticals, medicinal chemicals and botanical products': '制药、植物及化学药品制造',
            'Manufacture of plastic toys': '塑料玩具制造',
            'Manufacture of plastics in primary forms and of synthetic rubber ': '塑料原料及人造塑料制造',
            'Manufacture of printed circuits': '印刷电路版制造',
            'Manufacture of pulp ': '纸浆制造',
            'Manufacture of radio-broadcasting receivers and sound recording/ playing apparatus, including telephone answering machines, CD players, cassette-type recorders and cassette players, record players, and related products like amplifiers, loudspeakers, micro': '无线收音及录音器具包括电话录音机、CD机、录音机、唱片机及关连之产品如扩音机、扬声器及耳筒机制造',
            'Manufacture of railway and tramway locomotives and rolling stock': '路轨、电车车头及车厢制造',
            'Manufacture of refined petroleum products ': '精炼石油产品制造',
            'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres ': '橡胶轮胎制造、翻修及再造轮胎',
            'Manufacture of semi-manufactures of plastic products such as sheets, film, foil etc.': '半塑料制品如胶片、胶卷及胶纸等制造',
            'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations': '肥皂、清洁剂、清洁打蜡用品及厕所香香芬产品',
            'Manufacture of soft drinks; production of mineral waters': '矿泉水及软性饮品制造',
            'Manufacture of sports goods': '运动用品制造',
            'Manufacture of structural clay and ceramic products including ceramic building materials such as bricks, flooring blocks, roofing tiles and chimney-pots': '黏土及陶瓷制建筑材料如砖块、地砖、屋顶瓦片及烟窗制造',
            'Manufacture of sugar': '糖果制造',
            'Manufacture of television and radio receivers, sound or video recording or reproducing apparatus, and associated goods, n.e.c., including manufacture of specialized parts': '电视机、收音机，音响及视像录像器材及关连产品包括专用零件制造',
            'Manufacture of television and radio transmitters ': '电视机及无线电发射器制造',
            'Manufacture of television sets': '电视机组合制造',
            'Manufacture of textiles n.e.c.': '纺织品制造',
            'Manufacture of tobacco products ': '烟草制造',
            'Manufacture of toys made of textile materials e.g. stuffed toys': '布料玩具例如布公仔制造',
            'Manufacture of underwear, nightwear and swimwear ': '内衣、睡衣及泳衣制造',
            'Manufacture of unrecorded discs and tapes': '空白唱片及录音带制造',
            'Manufacture of vegetable and animal oils and fats': '蔬菜油及动物油制造',
            'Manufacture of veneer sheets, plywood, particle board and fibreboard ': '纤维板、三合板、合成板及木板制造',
            'Manufacture of video recording, playing or reproducing apparatus, including camcorder, digital cameras, video cassette players and VCD and DVD players': '视像录像、播放及复制用器具包括家庭手提摄影机、数码相机、录像机、VCD机及DVD机等制造',
            'Manufacture of watch and clock parts such as movements, springs, dials, plates, bridges, watch straps and other parts': '手表及时钟零件如手表运转装置、弹弓、指针、轮盘、桥、表带及其他零件制造',
            'Manufacture of watches and clocks': '手表及时钟制造',
            'Manufacture of wearing apparel and clothing accessories made of furskins or hides': '皮衣服装配件制造',
            'Manufacture of wearing apparel made of leather or composition leather': '皮衣服装制造',
            'Manufacture of wearing apparel, except fur apparel, n.e.c.': '制衣（皮衣除外）',
            'Manufacture of wooden containers': '木箱制造',
            'Manufacture of woven fabrics- cotton': '棉毛织品制造',
            'Manufacture of woven fabrics- others': '其他线织品制造',
            'Manufacture of yarn or thread': '线纱产品制造',
            'Market research and public opinion polling': '市场研究及公众意见收集',
            'Medical practice including clinics (excluding Chinese herbalists)': '私人执业医生包括诊所（不包括中医）',
            'Medical, Dental and X-Ray Laboratories': '医疗／牙科及X-光化验所',
            'Mining of coal and lignite; extraction of peat': '采煤矿及炭',
            'Mining of other metal ores': '开采其他金属、矿石',
            'Mining of uranium and thorium ores': '采铀和矿石',
            'Mobile network operators including paging service operators': '汽车运输网络人员包括传呼台员工',
            'Money services businesses (SCC)': '金融服务（找换、转账、支票贴现）',
            'Mortgage lenders': '按揭公司',
            'Motion picture and video production and distribution': '电影及影视产品制造及分销',
            'Motion picture projection ': '电影放影',
            'Movers': '搬屋公司',
            'Museums activities and preservation of historical sites and buildings': '博物馆及文物保护',
            'National post activities': '国际邮递服务',
            'News agency activities e.g. furnishing news pictures and features to the media and activities of journalists': '报馆及新闻机构',
            'Nominee companies': '代理人公司',
            'Non-Chinese Restaurants': '非中式餐馆',
            'Non-life insurance': '非人寿保险',
            'Non-scheduled air transport, including transport via space': '非定时航空运输包括太空运输',
            'Operation of slaughterhouses ': '经营屠宰场',
            'Operation of terminal facilities ': '货柜设施运作',
            'Operation of transport infrastructure e.g. roads, car parks, etc.': '交通基本设施运作如道路、停车场等',
            'Operation of web search portals and internet entertainment sites': '入门搜寻网站运作及互联网娱乐网址',
            'Other business activities n.e.c.': '其他商业活动',
            'Other credit granting by institutions not involved in monetary intermediation': '其他信贷公司不附属于中介金融机构',
            'Other education': '其他教育',
            'Other entertainment and recreational activities n.e.c. ': '其他演艺娱乐及消遣行业',
            'Other financial intermediation n.e.c., except insurance and pension funding ': '其他中介金融机构除保险及退休金管理',
            'Other human health activities n.e.c.': '其他人类健康医疗行业',
            'Other manufacturing n.e.c.': '其他制造业',
            'Other membership organizations n.e.c.': '其他会员组织团体',
            'Other mining and quarrying n.e.c.': '其他采矿行业',
            'Other monetary intermediation n.e.c.': '其他金融机构',
            'Other non-scheduled passenger land transport ': '其他陆路非定时乘客运输',
            'Other non-store retail sale, such as by door-to-door salespersons, vending machines or via internet auctions': '非门市零售如逐户推销人员、自动贩卖机或网上拍卖等',
            'Other publishing, including publishing of photos, postcards, posters, reproduction of works of art, etc': '其他出版如照片、明信片、海报及仿艺术品印刷',
            'Other retail sale in non-specialized stores': '非专门店零售士多',
            'Other retail sale in specialized stores, n.e.c.': '其他零售专门店',
            'Other scheduled passenger land transport': '其他陆路定时乘客运输',
            'Other service activities n.e.c. ': '其他服务行业包括家庭佣工',
            'Other software consultancy and supply': '其他软件顾问及供应',
            'Other supporting transport activities n.e.c.': '其他运输活动支持',
            'Other transport agencies n.e.c.': '其他交通运输代理商',
            'Other wholesale/ trading n.e.c.': '其他零售及批发',
            'Packaging activities': '包装服务',
            'Pension funding': '退休金管理',
            'Photographic activities ': '摄影及有关之行业',
            'Physiotherapy ': '物理治疗',
            'Political organizations': '政治团体',
            'Preservation and preparation of meat and meat products ': '肉类及肉类产品制造及保鲜',
            'Primary and pre-primary education': '小学及以下之教育',
            'Printing for others on a fee or contract basis': '以收费或合约形式经营印刷',
            'Processing and preserving of fish and fish products': '鱼类及鱼类产品处理及保鲜',
            'Processing and preserving of fruit and vegetables': '蔬果处理及保鲜',
            'Processing of nuclear fuel': '核子燃料处理',
            'Production and casting of other non-ferrous metals including copper, lead, chrome, manganese, zinc, aluminium, nickel, tin or other non-ferrous base metals and alloys of such metals': '铸造不含铁金属包括铜、铅、铬、锰、锌、铝、镍、锡及合金等',
            'Production of radio and television programmes': '电台及电视节目制作',
            'Production, collection and distribution of electricity': '电力生产、收集及供应',
            'Production, collection and distribution of steam and hot water for heating, power and other purposes': '水蒸气、热水加热及能量生产、收集及供应',
            'Professional organizations': '专业团体',
            'Property development- industrial': '物业发展 - 工业',
            'Property development- office/ shop': '物业发展 - 写字楼／商铺',
            'Property development- other': '物业发展 - 其他',
            'Property development- residential': '物业发展 - 住宅',
            'Property holding for rental – industrial': '出租自置物业 -工业',
            'Property holding for rental – office/ shop': '出租自置物业 - 写字楼／商铺',
            'Property holding for rental – residential': '出租自置物业 - 住宅',
            'Property holding for self-use': '物业发展 - 自用',
            'Publishing of books, brochures, musical books and other publications': '书籍出版',
            'Publishing of newspapers, journals and periodicals': '报纸、杂志及周刋出版',
            'Publishing of records and other recorded audio media': '唱片及其他录音媒体出版',
            'Real estate activities on a fee or contract basis': '以收费或合约经营地产行业（地产代理／拍卖）',
            'Real estate activities with own or leased property, n.e.c.': '与租用／自置物业有关之行业',
            'Recycling of metal waste and scrap': '金属废料循环再做',
            'Recycling of non-metal waste and scrap': '非金属废料循环再做',
            'Religious organizations': '宗教团体',
            'Renting of air transport equipment without operator': '航空交通工具出租不连操作人员',
            'Renting of construction or demolition equipment with operator': '出租建设和拆毁的装备',
            'Renting of land transport equipment without driver/ operator ': '陆上交通工具出租不连司机',
            'Renting of other machinery and equipment ': '其他机械设备出租',
            'Renting of personal and household goods n.e.c.': '私人及家居物品出租',
            'Renting of water transport equipment without operator': '水上交通工具出租不连操作人员',
            'Repair and alteration of personal and household goods': '个人及家居用品维修及改装',
            'Reproduction of recorded media- including records, audio and video tapes and computer software- from master copies': '生产影音及软件包括唱片、录像带及软件拷贝',
            'Research and development ': '研究及发展',
            'Restaurants, bars and canteens n.e.c.': '餐厅、酒吧及饭堂',
            'Restricted licensed banks': '有限制持牌银行附属金融机构',
            'Retail sale by mail order via mail order houses, television, radio, telephone or internet': '网上零售，经电话、电报认购或邮购',
            'Retail sale in non-specialized stores with food, beverages or tobacco predominating': '供应食品、饮料及香烟之非专门店零售士多',
            'Retail sale of antiques': '古物零售店',
            'Retail sale of articles of clothing, fur and clothing accessories': '成衣、皮草及制衣配件零售',
            'Retail sale of articles of lighting': '照明设备零售',
            'Retail sale of automotive fuel': '汽车燃料零售',
            'Retail sale of bakery products': '面包零售',
            'Retail sale of books, newspapers, magazines and stationery': '书簿、报纸、杂志及周刋零售',
            'Retail sale of dried seafood': '海产干货零售',
            'Retail sale of flowers, plants, seeds and fertilizers ': '花草、植物、种子及肥料零售',
            'Retail sale of food, beverages and tobacco in specialized stores n.e.c.': '供应食品、饮料及香烟之专门店',
            'Retail sale of footwear, leather goods and travel accessories of leather and leather substitutes': '鞋类、皮革制品，旅行用具及代用品零售',
            'Retail sale of games and toys': '游戏及玩具零售',
            'Retail sale of hardware, paints and glass': '铁器、油漆及玻璃零售',
            'Retail sale of household appliances, radio and television equipment and other household audio/ visual equipment ': '家居电器零售包括收音机、电视机及影音视听器材',
            'Retail sale of household articles of textile materials, including curtains ': '布制家居用品零售包括窗廉',
            'Retail sale of household furniture': '家居家俬零售',
            'Retail sale of household utensils, cutlery, crockery and kitchenware ': '器皿、餐具刀叉、陶器及厨房用具零售',
            'Retail sale of meat, fruit, vegetables, fishery (except dried seafood) and eggs': '肉类、生果、蔬菜、鱼类（不包括干货）及鸡蛋零售',
            'Retail sale of musical instruments and scores ': '音乐器材及乐谱零售',
            'Retail sale of musical records, audio/ visual tapes, CDs and cassettes ': '唱片、录音及录像带、CD及卞式录音带零售',
            'Retail sale of office equipment, computers and non-customized software': '办公室用品、电脑及非定做软件零售',
            'Retail sale of optical products': '光学产品零售',
            'Retail sale of perfumery, cosmetic and toilet articles ': '香水、美容化妆及卫生用品零售',
            'Retail sale of pet animals and food': '宠物及其食物零售',
            'Retail sale of pharmaceutical and medical goods': '医药制品零售',
            'Retail sale of photographic and precision equipment': '摄影精密器材零售',
            'Retail sale of second-hand goods in stores n.e.c.': '二手货物零售店',
            'Retail sale of sports goods ': '运动器材用品零售',
            'Retail sale of sugar, chocolate, confectionery, snacks and non-alcoholic beverages': '糖果、朱古力、糕饼、零食、西饼面包及非酒精饮品零售',
            'Retail sale of telecommunication equipment': '电讯器材零售',
            'Retail sale of textiles, knitting yarn, fabrics and basic materials for rug, tapestry or embroidery making': '纺织、布织、纺织品、地毯、绣帷、刺绣原料零售',
            'Retail sale of wallpaper, floor coverings, carpets and rugs': '墙纸、地板及地毯零售',
            'Retail sale of watches, clocks and jewellery': '手表、时钟及珠宝零售',
            'Retail sale of wine and liquors': '萄葡酒及酒精饮品零售',
            'Retail sale via stalls and markets': '街市摊档零售',
            'Sale and servicing of motor vehicles and motorcycles n.e.c.': '汽车及电单车零售及维修',
            'Sale and servicing of motorcycles and related parts and accessories': '电单车及零件出售及维修',
            'Sale of motor vehicle parts and accessories': '汽车零件出售',
            'Sale of new and used right-hand drive motor vehicles- retail': '全新及二手右軚车零售',
            'Sale of new and used right-hand drive motor vehicles- wholesale': '全新及二手右軚车批发',
            'Sawmilling and planing of wood': '林业及锯木',
            'Scheduled air transport': '定时航空运输',
            'Sea and coastal water transport ': '海上及沿海运输',
            'Securities custodians': '证券托管',
            'Security dealing in futures by broker on behalf of customers': '证券买卖-期货经纪',
            'Security dealing in options by broker on behalf of customers': '证券买卖-期权经纪',
            'Security dealing in shares by broker on behalf of customers': '证券买卖-经纪',
            'Security dealing n.e.c.': '证券买卖',
            'Service activities incidental to oil and gas extraction excluding surveying, provided on a fee or contract basis': '附属于提炼石油和天然气的以合约形式或收费业务（勘测除外）',
            'Service activities related to printing, including bookbinding and production of prepared printing plates ': '印刷服务包括钉装服务及制版等',
            'Servicing of motor vehicles': '汽车维修服务',
            'Sewage and refuse disposal, sanitation and similar activities': '污水及废物处理，卫生设施及同类服务',
            'Site preparation, including demolition of buildings, blasting, test drilling, and landfill': '地盘准备工程包括拆毁、爆破、钻探及填土',
            'Social work activities provided by government offices': '社会工作服务 - 政府提供',
            'Social work activities provided by private or charitable organizations': '社会工作服务 - 私人机构提供',
            'Software publishing': '软件出版发行',
            'Sporting activities, including the operation of the facilities in which various sports are performed': '体育及康乐组织',
            'Storage and warehousing': '储存及货仓',
            'Tanning and dressing of leather': '皮革加工',
            'Technical and vocational secondary education': '工艺及职业中学教育',
            'Technical testing and analysis of all types of materials and products ': '各类材料及产品技术测试及分析',
            'Telecommunications, n.e.c.': '电讯服务',
            'Trade unions': '劳工组织',
            'Trading of agricultural raw materials and live animals': '农产品原料及生禽零售',
            'Trading of beer, wine and liquors': '啤酒、萄葡酒及酒类零售',
            'Trading of blank audio and video tapes and diskettes, and magnetic and optical disks': '录音带、录像带、磁盘及光盘零售',
            'Trading of clothing and accessories ': '成衣及配件零售',
            'Trading of coffee, tea, cocoa and spices': '咖啡、红茶、可可及香料零售',
            'Trading of computers, computer peripheral equipment and software': '电脑、电脑外设产品及软件零售',
            'Trading of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.': '建筑材料、五金、水管、暖气设备及供应零售',
            'Trading of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.': '家居电动及电子器具零售（如雪柜、电灯、收音机及电视器具等）',
            'Trading of dried seafood': '海产干货之零售',
            'Trading of edible oils and fats': '粮油食品零售',
            'Trading of electrical material': '电工材料零售',
            'Trading of electronic and telecommunications parts and equipment n.e.c.': '电子及电信零件及设备零售',
            'Trading of electronic valves and tubes': '电子真空管零售',
            'Trading of food, beverages and tobacco n.e.c.': '食物、饮料及香烟零售',
            'Trading of footwear': '鞋类零售',
            'Trading of games and toys, musical instruments, sports goods and bicycles ': '游戏及玩具、音乐器材、运动器材及单车零售',
            'Trading of general purpose machinery and machine tools': '特别用途机器及工具零售',
            'Trading of gold and other precious metals ': '黄金及其他贵金属零售',
            'Trading of household ceramic ware, glassware, cutlery and kitchenware': '家居陶瓷器皿、玻璃器皿、餐具刀叉及厨房器皿零售',
            'Trading of household furniture': '家用家俬零售',
            'Trading of industrial chemicals, fertilizers and agrochemical products': '化工材料、肥料及农药产品零售',
            'Trading of leather goods (including travel accessories) and paper products': '皮革制品（包括旅行用具）及纸类产品零售',
            'Trading of machinery for textile, apparel and leather production': '纺织、制衣及皮革制造机器零售',
            'Trading of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ': '肉类、生果、蔬菜、鱼类（不包括干货）、鸡蛋及乳类产品零售',
            'Trading of medical and precision instruments': '医疗及精密仪器零售',
            'Trading of metal and non-metal waste and scrap and materials for recycling': '循环再用金属及非金属废料零售',
            'Trading of office furniture': '办公室家俬零售',
            'Trading of office machinery and equipment, except computers and computer peripheral equipment': '办公室机器设备零售，不包括电脑及电脑外设产品',
            'Trading of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ': '含铁及不含铁金属零售，包括铁矿、金属原料及半制成金属产品',
            'Trading of other household goods, n.e.c.': '其他家居用品零售',
            'Trading of other intermediate products, waste and scrap n.e.c.': '废料零售',
            'Trading of other machinery, equipment and supplies n.e.c.': '其他机器及工具设备零售',
            'Trading of other special purpose machinery ': '特别用途机器零售',
            'Trading of paint and varnish': '油漆及颜料零售',
            'Trading of pharmaceutical and medical goods, perfumerie, cosmetics and soaps': '制药、医疗用品、香水、美容化妆品及肥皂零售',
            'Trading of photographic and optical goods': '摄影及光学产品零售',
            'Trading of plastic materials in primary forms': '塑料原料零售',
            'Trading of precious stones': '宝石零售',
            'Trading of pulp and paper in bulk': '纸张及纸桨零售',
            'Trading of rice and cereals': '稻米及谷物零售',
            'Trading of rubber': '橡胶原料零售',
            'Trading of sanitary equipment and sanitary installation equipment': '厕所卫生设备及装置工具零售',
            'Trading of semi-conductor devices, microchips, integrated circuits and printed circuits ': '半导体装置、电脑芯片、集成电路板及印刷电路板零售',
            'Trading of solid, liquid and gaseous fuels and related products': '固体、液体、气体燃料及有关产品零售',
            'Trading of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages': '肉类、生果、蔬菜、鱼类（不包括干货）、鸡蛋及乳类产品零售',
            'Trading of telephone and communications equipment': '电话及电话设备零售',
            'Trading of textile fibres': '纺织材料零售',
            'Trading of textiles, clothing and footwear n.e.c.': '纱线、纺织及家用麻制品零售',
            'Trading of tobacco products': '香烟零售',
            'Trading of tools, hardware, fittings and fixtures': '工具、五金、装修及固定装置零售',
            'Trading of transport equipment except motor vehicles, motorcycles and bicycles': '交通工具零售，不包括汽车、电单车及单车',
            'Trading of watches, clocks and jewellery': '手表、时钟及珠宝零售',
            'Trading of wood, sand, stone, clay, cement, glass and related construction materials ': '木、沙、石、泥土、水泥、玻璃及其他建筑材料零售',
            'Trading of yarn, fabrics and household linens': '纱线、纺织及家用麻制品零售',
            'Transport via pipelines, including transport of gases, liquids, slurry and other commodities via pipelines': '管道运输包括运送气体、液体、泥桨及其他经管道运送之货品',
            'Transport via railways': '铁路交通',
            'Treatment and coating of metals; general mechanical engineering on a fee or contract basis': '铁外层处理及涂料；一般收费或合约形式之机械工程',
            'Trusts/ Trustees': '信托公司',
            'Veterinary activities': '兽医',
            'Washing, and (dry-) cleaning of textile and fur products': '洗衣、干洗成衣及皮衣',
            'Wholesale and retail of left-hand drive motor vehicles': '全新及二手左軚车批发及零售',
            'Wholesale of agricultural raw materials and live animals': '农产品原料及生禽批发',
            'Wholesale of beer, wine and liquors': '啤酒、萄葡酒及酒类批发',
            'Wholesale of blank audio and video tapes and diskettes, and magnetic and optical disks': '录音带、录像带、磁盘及光盘批发',
            'Wholesale of clothing and accessories ': '成衣及附属用品批发',
            'Wholesale of coffee, tea, cocoa and spices': '咖啡、红茶、可可及香料批发',
            'Wholesale of computers, computer peripheral equipment and software': '电脑、电脑外设产品及软件批发',
            'Wholesale of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.': '建筑材料、五金、水管、暖气设备及供应批发',
            'Wholesale of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.': '家居电力及电子器具批发（如雪柜、电灯、收音机及电视器具等）',
            'Wholesale of dried seafood': '晒干之海产批发',
            'Wholesale of edible oils and fats': '粮油食品批发',
            'Wholesale of electrical material': '电工材料批发',
            'Wholesale of electronic and telecommunications parts and equipment n.e.c.': '电子及电信零件及设备批发',
            'Wholesale of electronic valves and tubes': '电子真空管批发',
            'Wholesale of food, beverages and tobacco n.e.c.': '食物、饮料及香烟批发',
            'Wholesale of footwear': '鞋类批发',
            'Wholesale of games and toys, musical instruments, sports goods and bicycles ': '游戏及玩具、音乐器材、运动器材及单车批发',
            'Wholesale of general purpose machinery and machine tools': '特别用途机器及工具批发',
            'Wholesale of gold and other precious metals ': '黄金及其他贵金属批发',
            'Wholesale of household ceramic ware, glassware, cutlery and kitchenware': '家居陶瓷器皿、玻璃器皿、餐具刀叉及厨房器皿批发',
            'Wholesale of household furniture': '家居家俬批发',
            'Wholesale of industrial chemicals, fertilizers and agrochemical products': '化工材料、肥料及农药产品批发',
            'Wholesale of leather goods (including travel accessories) and paper products': '皮革制品（包括旅行用具）及纸类产品批发',
            'Wholesale of machinery for textile, apparel and leather production': '纺织、制衣及皮革制造机器批发',
            'Wholesale of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ': '肉类、生果、蔬菜、鱼类（不包括干货）、鸡蛋及乳类产品批发',
            'Wholesale of medical and precision instruments': '医疗及精密仪器批发',
            'Wholesale of metal and non-metal waste and scrap and materials for recycling': '循环再用金属及非金属废料批发',
            'Wholesale of office furniture': '办公室家俬批发',
            'Wholesale of office machinery and equipment, except computers and computer peripheral equipment': '办公室机器设备批发，不包括电脑及电脑外设产品',
            'Wholesale of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ': '含铁及不含铁金属批发，包括铁矿、金属原料及半制成金属产品',
            'Wholesale of other household goods, n.e.c.': '其他家居用品批发',
            'Wholesale of other intermediate products, waste and scrap n.e.c.': '废料批发',
            'Wholesale of other machinery, equipment and supplies n.e.c.': '其他机器及工具设备批发',
            'Wholesale of other special purpose machinery': '其他特殊用途机械批发',
            'Wholesale of paint and varnish': '油漆及颜料批发',
            'Wholesale of pharmaceutical and medical goods, perfumerie, cosmetics and soaps': '制药、医疗用品、香水、美容化妆品及肥皂批发',
            'Wholesale of photographic and optical goods': '摄影及光学产品批发',
            'Wholesale of plastic materials in primary forms': '塑料原料批发',
            'Wholesale of precious stones': '宝石批发',
            'Wholesale of pulp and paper in bulk': '纸张及纸桨批发',
            'Wholesale of rice and cereals': '稻米及谷物批发',
            'Wholesale of rubber': '橡胶原料批发',
            'Wholesale of sanitary equipment and sanitary installation equipment': '厕所卫生设备及装置工具批发',
            'Wholesale of semi-conductor devices, microchips, integrated circuits and printed circuits ': '半导体装置、电脑芯片、集成电路板及印刷电路板批发',
            'Wholesale of solid, liquid and gaseous fuels and related products': '固体、液体、气体燃料及有关产品批发',
            'Wholesale of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages': '糖果、朱古力、糕饼、零食、西饼面包及非酒精饮品批发',
            'Wholesale of telephone and communications equipment': '电话及电话设备批发',
            'Wholesale of textile fibres': '纺织材料批发',
            'Wholesale of textiles, clothing and footwear n.e.c.': '纺织品、成衣及鞋类批发',
            'Wholesale of tobacco products': '香烟批发',
            'Wholesale of tools, hardware, fittings and fixtures': '工具、五金、装修及固定装置批发',
            'Wholesale of transport equipment except motor vehicles, motorcycles and bicycles': '交通工具批发，不包括汽车、电单车及单车',
            'Wholesale of watches, clocks and jewellery': '手表、时钟及珠宝批发',
            'Wholesale of wood, sand, stone, clay, cement, glass and related construction materials ': '木、沙、石、泥土、水泥、玻璃及其他建筑材料批发',
            'Wholesale/ Trading on a fee or contract basis, such as commission agents, commodity brokers and auctioneers ': '以合约或收费形式经营零售及批发，例如收佣金之代理人、商品经纪及拍卖员等',

        },

        employmentStatusList: [
            {
                value: 'SELF_EMPLOYED',
                label: '自雇'
            },
            {
                value: 'FULL_TIME_EMPLOYMENT',
                label: '全职'
            },
            {
                value: 'PART_TIME_EMPLOYMENT',
                label: '兼职'
            },
            {
                value: 'UNEMPLOYED',
                label: '无业'
            },
            {
                value: 'STUDENT',
                label: '学生'
            },
            {
                value: 'HOMEMAKER',
                label: '持家者'
            },
            {
                value: 'RETIRED',
                label: '退休'
            }
        ],

        residentialReasonList: [
            {value: 'A', label: "本人为现居住地址国家／地区的学生，但居住时间不足以成为当地税务居民。"},
            {value: 'B', label: "本人在现居住地址国家／地区工作，但居住时间不足以成为当地税务居民。"},
            {value: 'C', label: "本人为派驻于现居住地址国家／地区的外交官或军人。"},
            {value: 'D', label: "本人在近期移居到现居住地址国家／地区，但尚未成为当地税务居民。"},
            {value: 'E', label: '上述选项皆不符合。'}
        ],
        correspondenceReasonList: [
            {value: 'A', label: "我的通信地址为我的顾问、会计师或律师的地址。"},
            {value: 'B', label: "我的通信地址为我的亲属的地址。"},
            {value: 'C', label: "我的通信地址为我的工作／公司的地址。"},
            {value: 'D', label: "我的通信地址为我在度假时所居住的地址。"},
            {value: 'E', label: '上述选项皆不符合。'}
        ],
    },
    zh_HK: {
        occupation: [
            { value: 'Accountant / auditor ', label: '會計師／核數師' },
            { value: 'Architect', label: '建築師' },
            { value: 'Artist/performer/producer/athlete', label: '藝人／表演者／監製／運動員' },
            { value: 'Business owner/partner/director/senior management', label: '企業東主／合夥人／總監／高級管理層' },
            { value: 'Chef/server', label: '廚師／侍應員' },
            { value: 'Clerical/admin/secretarial', label: '文員／行政人員／秘書' },
            { value: 'Construction worker', label: '建造業工作者' },
            { value: 'Consultant', label: '顧問' },
            { value: 'Data processer /IT manager', label: '數據處理員／資訊科技經理' },
            { value: 'Domestic helper/cleaner', label: '家務助理／清潔員' },
            { value: 'Driver', label: '司機' },
            { value: 'Engineer ', label: '工程師' },
            { value: 'Farmer/fisherperson/forester', label: '農業／漁業／林業工作者' },
            { value: 'Financial Controller', label: '財務總監' },
            { value: 'Firefighter', label: '消防員' },
            { value: 'Flight attendant/airport ground crew/pilot', label: '空中服務員／機場地勤人員／飛機師' },
            { value: 'Immigration services officer', label: '入境事務主任' },
            { value: 'Inland revenue officer ', label: '稅務主任' },
            { value: 'Insurance agent/brokers', label: '保險代理人／經紀' },
            { value: 'Investment / fund manager', label: '投資／基金經理' },
            { value: 'Lawyer/solicitor', label: '律師／事務律師' },
            { value: 'Marketing', label: '市場推廣人員' },
            { value: 'Medical professional ', label: '醫務專業人員' },
            { value: 'Military', label: '軍事人員' },
            { value: 'Other licensed professional', label: '其他持牌專業人員' },
            { value: 'Other manual/ skilled worker ', label: '其他體力勞動／技術工作者' },
            { value: 'Other office worker/semi-professional', label: '其他辦公室／半專業工作者' },
            { value: 'Other service worker (e.g. hospitality, personal services)', label: '其他服務工作者（如款待業及私人服務）' },
            { value: 'Police', label: '警察' },
            { value: 'Politician/ambassador/consul-general', label: '從政者／大使／總領事' },
            { value: 'Postal worker', label: '郵政服務人員' },
            { value: 'Prison/correctional services officer', label: '監獄／懲教服務人員' },
            { value: 'Property agent', label: '地產經紀' },
            { value: 'Salesperson', label: '銷售員' },
            { value: 'Security guard', label: '保安' },
            { value: 'Senior government/judicial official', label: '高級政府／司法人員' },
            { value: 'Social worker', label: '社會工作者' },
            { value: 'Teacher/principal/lecturer/professor', label: '教師／校長／講師／教授' },
            { value: 'Traffic warden', label: '交通督導員' },
        ],
        employmentRoleList: [
            { value: 'Business Owner', label: '企業東主' },
            { value: 'Employee', label: '僱員' },
            { value: 'Key Controller', label: '主要管理人' },
            { value: 'Sole Trader', label: '獨資經營者' },
        ],
        industryList: {
            'Accounting, book-keeping and auditing activities; tax consultancy': '會計、部記及核數事務；稅務顧問',
            'Activities auxiliary to financial intermediation n.e.c., except to insurance and pension funding ': '金融中介公司輔助機構（不包括保險及退休金公司）',
            'Activities auxiliary to insurance and pension funding': '保險及退休金公司輔助機構',
            'Activities normally carried out by the public administration': '公共管理服務，政府',
            'Activities of travel agencies and tour operators; tourist assistance activities n.e.c.': '旅行社及導遊',
            'Administration of financial markets, including the activities of stock exchanges and exchanges for commodity futures contracts': '金融市場管理公司-股票及商品期貨',
            'Advertising': '廣告',
            'Agricultural and animal husbandry service activities, except veterinary activities': '農務及家畜飼養行業（獸醫除外）',
            'Architectural and engineering activities and related technical consultancy, n.e.c.': '建築及工程事務及有關技術顧問服務',
            'Architectural consulting activities ': '建築顧問事務',
            'Banks': '銀行',
            'Bars': '酒吧',
            'Bath house, sauna & massage salons': '浴室、桑拿浴室及按摩院',
            'Botanical and zoological gardens and nature reserves activities': '動植物花園及自然保護行業',
            'Building and repairing of ships and boats': '船艦、舢板製造及修理',
            'Building completion': '建築物完工',
            'Building installation n.e.c.': '建築物裝置工程',
            'Building installation, including the installation in buildings of all kinds of utilities ': '建築物裝置包括建築物內所有用具設施',
            'Building of complete constructions or parts thereof; civil engineering, n.e.c.': '建築物完工及部份建造工程、土木工程',
            'Building-cleaning and industrial-cleaning activities': '大廈清潔及工業清潔服務',
            'Business and employers organizations': '商業及僱主團體',
            'Business and management consultancy activities': '商業及管理顧問服務',
            'Cargo handling': '貨物處理',
            'Casino and other types of gaming/gambling operation (SCC)': '賭場及娛樂場所行業',
            'Central banking': '中央銀行',
            'Chinese herbalists/ medical practice including acupuncture': '中醫及針灸',
            'Chinese Restaurants': '中式餐館',
            'Chiropractice': '脊椎指壓治療／按摩',
            'Civil engineering including construction of highways, streets, bridges, tunnels, railways, sewerage systems, etc. ': '土木工程包括天橋、街道、橋樑、隧道、鐵路及排水系統',
            'Clubs, karaoke & entertainment lounges': '夜總會、卡啦OK及同類娛樂場所',
            'Collection, purification and distribution of water': '食水收集、供應及過濾',
            'Compilation of data and provision of on-line access to proprietary databases': '資料編輯及聯系至專有之資料庫支援',
            'Courier activities other than national post activities': '速遞不包括國際郵遞服務',
            'Custom tailoring': '裁縫師',
            'Cutting, shaping and finishing of stone for use in construction, in cemeteries, on roads, as roofing and in other applications': '打磨、切割石頭以供建築、墓碑、舖路或蓋屋頂',
            'Data processing ': '電子資料處理',
            'Database activities and on-line distribution of electronic content n.e.c.': '資料庫活動及聯線發放電子內容',
            'Dental practice': '牙醫',
            'Deposit-taking companies': '存款公司附屬金融機構',
            'Dramatic arts, music and other arts activities': '戲劇藝術、音樂及其他藝術行業',
            'Dressing and dyeing of fur; manufacture of articles of fur, n.e.c.': '皮草漂染及加工',
            'Dressing and dyeing of furskins': '皮革漂染及服飾加工',
            'Engineering and related technical consultancy activities ': '工程及有關技術顧問服務',
            'Export credit agencies': '出口信貸代理公司',
            'Extra-territorial organizations and bodies ': '不分地區組織團體／機構',
            'Extraction of crude petroleum and natural gas': '提煉石油和天然氣',
            'Factoring institutions': '經銷商',
            'Farming of animals and livestock': '飼養家畜',
            'Fast Food Shops': '快餐店',
            'Finance houses': '財務公司',
            'Financial leasing': '財務租貸公司',
            'Finishing of textiles by such operations as dyeing or printing': '紡織品漂染及印花',
            'Fishing, aquaculture and service activities incidental to fishing': '捕魚業、養魚業及有關業務',
            'Fixed telecommunications network services (FTNS) operators': '固定電信網絡服務員',
            'Forestry, logging and related service activities': '森林管理、伐木業和有關業務',
            'Forging, pressing, stamping and roll-forming of metal; powder metallurgy': '鍊造、壓造及滾筒壓鐵；冶金術',
            'Freight forwarders': '貨物運輸',
            'Freight transport by road': '陸路貨物運輸',
            'Fund manager (as agent re named fund)': '基金經理 - 記名基金代理',
            'Fund manager (as agent re unnamed fund)': '基金經理 - 不記名基金代理',
            'Fund manager (as hedge fund)': '基金經理 - 對沖基金',
            'Fund manager (as principle)': '基金經理',
            'Funeral and related activities': '殯儀及有關之服務',
            'General construction including new works, additions and alterations': '一般建設包新建造、加建及改造工程',
            'General secondary education': '一般中學教育',
            'Growing of crops combined with farming of animals (mixed farming)': '榖物生產／飼養家畜（混合耕作）',
            'Growing of crops, market gardening, horticulture': '穀物生產、種植蔬菜出售的行業',
            'Hairdressing and other beauty treatment': '理髮及美容院',
            'Hardware consultancy': '電腦硬件顧問服務',
            'Higher education, including post-secondary sub-degree level education and university education': '高等 ／大學教育',
            'Holding of raw land for sale or future development': '持有未開發土地供銷售或發展用途',
            'Hospitals': '醫護行業',
            'Hotels; camping sites and other provision of short-stay accommodation ': '酒店、露營用地及公寓',
            'Hunting, trapping and game propagation including related service activities': '狩獵、捕捉及繁殖包括有關業務',
            'Inland water transport': '內陸水道運輸',
            'Installation of illumination and signaling systems for roads, railways, airports, harbours, etc.': '道路、鐵路、機場及碼頭照明及訊號系統裝置',
            'Internet access provision': '互聯網供應商',
            'Investigation and security activities ': '調查及保安事務',
            'Investment in securities (proprietary holdings)': '全東証券投資公司',
            'Knitting or crocheting of wearing apparel directly to shape ': '針織及鈎針編織成衣製造',
            'Labour recruitment and provision of personnel ': '人事招募及人事支援行業',
            'Legal activities': '法律事務',
            'Library and archives activities': '圖書館及檔案管理',
            'Licensed banks': '持牌銀行附屬金融機構',
            'Life insurance': '人壽保險',
            'Maintenance and repair of office, accounting and computing machinery': '辦公室機器、電腦維修服務',
            'Manufacture and casting of precious metals including gold, silver, and metals of the platinum group, including production of clad precious metals': '鑄造貴金屬包括金、銀、白金及鍍金',
            'Manufacture and distribution of arms, other military products (SCC)': '軍械及武器製造',
            'Manufacture of accumulators, primary cells and primary batteries': '蓄電池、電池及電蕊製造',
            'Manufacture of aircraft and spacecraft': '飛機及太空船製造',
            'Manufacture of alcoholic drinks including non-alcoholic beer and wine': '酒精飲品製造包括不含酒精啤酒及葡萄酒',
            'Manufacture of all kinds of clothing accessories ': '各類衣服配件',
            'Manufacture of apparatus for line telephony and line telegraphy': '有線電話及電報機製造',
            'Manufacture of articles of cork, straw and plaiting materials': '瓶塞、稻草編成之產品製造',
            'Manufacture of articles of fur such as rugs, unstuffed pouffes, etc.': '毛毯厚咕產品製造',
            'Manufacture of articles of leather or composition leather n.e.c.': '皮革及皮革合成品製造',
            'Manufacture of artificial flowers and trees': '人工花樹製造',
            'Manufacture of bakery products': '麵包製造',
            'Manufacture of basic chemicals ': '基本化學品製造',
            'Manufacture of basic iron and steel': '基本鋼鐵製造',
            'Manufacture of bicycles and invalid carriages': '單車及殘疾人輪椅製造',
            "Manufacture of builders' carpentry and joinery, including manufacture of wooden goods primarily for the construction industry ": '建築業大小木匠包括主要作建築用途之木器產品製造',
            'Manufacture of carpets and rugs': '地毯製造',
            'Manufacture of cathode tubes or valves e.g. television picture tubes, television camera tubes, etc.': '陰極管、真空管製造',
            'Manufacture of cement, lime and plaster and articles thereof, such as boards, ready-mixed concrete, articles of asbestos-cement, etc.': '水泥、石灰、石膏製品如石板、混凝土及石綿產品製造',
            'Manufacture of cocoa, chocolate and sugar confectionery': '可可、朱古力及糖果糕點製造',
            'Manufacture of coke oven products ': '炭爐產品製造',
            'Manufacture of containers made of non-corrugated paper e.g. cartons, bags, etc.': '紙袋及紙箱紙盒製造',
            'Manufacture of cordage, rope, twine and netting': '船纜、麻繩及繩製造',
            'Manufacture of corrugated paper and corrugated boxes': '皺紋紙及紙箱製造',
            'Manufacture of cutlery, hand tools and general hardware': '刀劍利器製造、一般工具及鐵器',
            'Manufacture of dairy products': '奶品製造',
            'Manufacture of electric lamps and lighting equipment': '電燈及照明用具製造',
            'Manufacture of electric motors, generators and transformers ': '電動摩打、發電機及變壓器製造',
            'Manufacture of electricity distribution and control apparatus such as switches, fuses, lightning arresters, voltage limiters, surge suppressors, plugs, junction boxes, etc': '輸電及控製器具如電掣、保險絲、避雷器、電壓計、漏電斷路掣、插頭及接合器等製造',
            'Manufacture of electronic components n.e.c. e.g. diodes, transistors and similar semi-conductor devices, electrical capacitors or condensers, resistors, LCD displays, etc.': '電子產品如兩極真空管、電晶體、半導體、電容器、電阻及液晶體顯示器等製造',
            'Manufacture of electronic games e.g. handheld electronic games, video games, etc., and toy musical instruments': '電子遊戲例如手遊戲機、影視遊戲及音樂玩具器材等製造',
            'Manufacture of electronic toys, e.g. radio-controlled/ infra-red controlled toy vehicles, educational toys, etc.': '電子玩具例如無線或紅外線搖控玩具車及教育玩具等製造',
            'Manufacture of finished plastic products ': '塑膠完成品製造',
            'Manufacture of footwear – leather uppers': '皮鞋製造-鞋面',
            'Manufacture of footwear – rubber or plastic uppers': '皮鞋製造-橡膠或塑膠鞋面',
            'Manufacture of footwear – textile uppers': '皮鞋製造-布面',
            'Manufacture of footwear, n.e.c.': '皮鞋製造',
            'Manufacture of furniture, including mattresses': '傢俬製造包括褥墊',
            'Manufacture of games and toys, n.e.c.': '遊戲及玩具製造',
            'Manufacture of gas; distribution of gaseous fuels through mains': '氣體燃料製造及供應商',
            'Manufacture of general purpose machinery and parts thereof': '一般用途之機械設置及零件製造',
            'Manufacture of glass and glass products': '玻璃及玻璃產品製造',
            'Manufacture of grain mill products, starches and starch products, and prepared animal feeds': '製造碾磨榖粒、澱粉和動物飼料',
            'Manufacture of hosiery, including socks, tights and panty hose': '針織品、襪、緊身襯衣及褲襪製造',
            'Manufacture of household appliances n.e.c. ': '家庭用器具製造',
            'Manufacture of instruments and appliances for measuring, checking, testing, navigating and other purposes, ': '量度、檢驗、測試及操縱儀器及裝置製造',
            'Manufacture of insulated wire and cable': '絕緣電線及電纜製造',
            'Manufacture of integrated circuits': '積體電路版製造',
            'Manufacture of jewellery and related articles': '珠寶及附屬商品製造',
            'Manufacture of knitted or crocheted articles n.e.c.': '機織及鉤針編織品製造',
            'Manufacture of knitted or crocheted fabrics': '針織及鈎針編織製造',
            'Manufacture of luggage, handbags and the like ': '手袋及行李箱製造',
            'Manufacture of macaroni, noodles, couscous and similar farinaceous products': '通心粉、麵及麵粉類產品製造',
            'Manufacture of machine-tools and parts and accessories': '機器工具及零件及配件',
            'Manufacture of machinery and equipment n.e.c.': '機械及用具製造',
            'Manufacture of machinery for textile, apparel and leather production': '紡織機、縫紉機及製皮機器',
            'Manufacture of man-made fibres ': '人造纖維製造',
            'Manufacture of medical and surgical equipment and orthopedic appliances': '醫療、外科手術及整形外科器材製造',
            'Manufacture of metal structures, including bridges and bridge parts, towers, masts and columns, doors and windows and their frames, games, etc. ': '鐵製成品如鐵橋及其零件、塔、柱、圓柱、門窗及框架、比賽設備等',
            'Manufacture of metal tanks, reservoirs and containers of types normally installed as fixtures ': '固定裝置之鐵槽、貯水池或貯水箱等製造',
            'Manufacture of metal toys': '金屬玩具製造',
            'Manufacture of motor vehicles': '汽車製造',
            'Manufacture of motorcycles': '摩托車製造',
            'Manufacture of musical instruments': '音樂器材製造',
            'Manufacture of non-structural ceramic products including articles of porcelain or china, such as tableware and sanitary fixtures': '陶瓷及磁器製品如餐具或厠所設備製造',
            'Manufacture of nuclear reactors and steam generators, except central heating hot water boilers': '核反應爐、蒸氣機（中央熱水爐除外）製造',
            'Manufacture of office, accounting and computing machinery including typewriters, photo-copying apparatus, data-processing machines, etc': '辦公室使用的機器如打字機、影印機及電腦等製造',
            'Manufacture of optical instruments and photographic equipment e.g. optical frames, cameras, projectors, etc.': '光學儀器及攝影裝置例如光學攝影機及放影機等製造',
            'Manufacture of other articles of paper and paperboard ': '其他紙張及卡紙製成品',
            'Manufacture of other chemical products n.e.c. ': '其他化學品製造',
            'Manufacture of other electrical equipment n.e.c.': '其他電器用具製造',
            'Manufacture of other fabricated metal products n.e.c.': '偽造金屬產品製造',
            'Manufacture of other food products n.e.c. ': '其他食品製造',
            'Manufacture of other non-metallic mineral products n.e.c.': '非金屬礦產品製造',
            'Manufacture of other products of wood': '其他木器製造',
            'Manufacture of other rubber products ': '其他橡膠產品製造',
            'Manufacture of other special purpose machinery chiefly employed by a particular industry or by a number of related industries': '特別用途之機器製造',
            'Manufacture of other textiles n.e.c.': '其他紡織品製造業',
            'Manufacture of other transport equipment n.e.c.': '其他交通工具製造',
            'Manufacture of outerwear made of knitted or crocheted fabrics': '機織及鉤針編織外衣製造',
            'Manufacture of outerwear made of woven fabrics': '紡織外衣製造',
            'Manufacture of paints, varnishes and similar coatings, printing ink and mastics': '油漆、光漆，塗層、油墨及灰泥製造',
            'Manufacture of paper or paperboard in bulk, in rolls or sheets ': '普通紙及卡紙業',
            'Manufacture of parts and accessories for motor vehicles, including engines, bodies (coachwork) and trailers ': '汽車零件及附屬用品包括引擎、車身及拖架等製造',
            'Manufacture of parts of footwear': '皮鞋配件製造',
            'Manufacture of pesticides and other agro-chemical products ': '殺蟲劑、農藥製造',
            'Manufacture of pharmaceuticals, medicinal chemicals and botanical products': '製藥、植物及化學藥品製造',
            'Manufacture of plastic toys': '塑膠玩具製造',
            'Manufacture of plastics in primary forms and of synthetic rubber ': '塑膠原料及人造塑膠製造',
            'Manufacture of printed circuits': '印刷電路版製造',
            'Manufacture of pulp ': '紙漿製造',
            'Manufacture of radio-broadcasting receivers and sound recording/ playing apparatus, including telephone answering machines, CD players, cassette-type recorders and cassette players, record players, and related products like amplifiers, loudspeakers, micro': '無線收音及錄音器具包括電話答錄機、CD機、錄音機、唱片機及關連之產品如擴音機、揚聲器及耳筒機製造',
            'Manufacture of railway and tramway locomotives and rolling stock': '路軌、電車車頭及車廂製造',
            'Manufacture of refined petroleum products ': '精煉石油產品製造',
            'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres ': '橡膠輪胎製造、翻修及再造輪胎',
            'Manufacture of semi-manufactures of plastic products such as sheets, film, foil etc.': '半塑膠製品如膠片、膠卷及膠紙等製造',
            'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations': '肥皂、清潔劑、清潔打蠟用品及厠所香芬產品',
            'Manufacture of soft drinks; production of mineral waters': '磺泉水及軟性飲品製造',
            'Manufacture of sports goods': '運動用品製造',
            'Manufacture of structural clay and ceramic products including ceramic building materials such as bricks, flooring blocks, roofing tiles and chimney-pots': '黏土及陶瓷製建築材料如磚塊、地磚、屋頂瓦片及煙窗製造',
            'Manufacture of sugar': '糖果製造',
            'Manufacture of television and radio receivers, sound or video recording or reproducing apparatus, and associated goods, n.e.c., including manufacture of specialized parts': '電視機、收音機，音響及視像錄影器材及關連產品包括專用零件製造',
            'Manufacture of television and radio transmitters ': '電視機及無線電發射器製造',
            'Manufacture of television sets': '電視機組合製造',
            'Manufacture of textiles n.e.c.': '紡織品製造',
            'Manufacture of tobacco products ': '煙草製造',
            'Manufacture of toys made of textile materials e.g. stuffed toys': '布料玩具例如布公仔製造',
            'Manufacture of underwear, nightwear and swimwear ': '內衣、睡衣及泳衣製造',
            'Manufacture of unrecorded discs and tapes': '空白唱片及錄音帶製造',
            'Manufacture of vegetable and animal oils and fats': '蔬菜油及動物油製造',
            'Manufacture of veneer sheets, plywood, particle board and fibreboard ': '纖維板、三合板、合成板及木板製造',
            'Manufacture of video recording, playing or reproducing apparatus, including camcorder, digital cameras, video cassette players and VCD and DVD players': '視像錄影、播放及複製用器具包括家庭手提攝影機、數碼相機、錄影機、VCD機及DVD機等製造',
            'Manufacture of watch and clock parts such as movements, springs, dials, plates, bridges, watch straps and other parts': '手錶及時鐘零件如手錶運轉裝置、彈弓、指針、輪盤、橋、錶帶及其他零件製造',
            'Manufacture of watches and clocks': '手錶及時鐘製造',
            'Manufacture of wearing apparel and clothing accessories made of furskins or hides': '皮衣服裝配件製造',
            'Manufacture of wearing apparel made of leather or composition leather': '皮衣服裝製造',
            'Manufacture of wearing apparel, except fur apparel, n.e.c.': '製衣（皮衣除外）',
            'Manufacture of wooden containers': '木箱製造',
            'Manufacture of woven fabrics- cotton': '棉毛織品製造',
            'Manufacture of woven fabrics- others': '其他綫織品製造',
            'Manufacture of yarn or thread': '綫紗產品製造',
            'Market research and public opinion polling': '市場研究及公眾意見收集',
            'Medical practice including clinics (excluding Chinese herbalists)': '私人執業醫生包括診所（不包括中醫）',
            'Medical, Dental and X-Ray Laboratories': '醫療／牙科及X-光化驗所',
            'Mining of coal and lignite; extraction of peat': '採煤礦及炭',
            'Mining of other metal ores': '開採其他金屬、礦石',
            'Mining of uranium and thorium ores': '採鈾和礦石',
            'Mobile network operators including paging service operators': '汽車運輸網絡人員包括傳呼台員工',
            'Money services businesses (SCC)': '金融服務（找換、轉賬、支票貼現）',
            'Mortgage lenders': '按揭公司',
            'Motion picture and video production and distribution': '電影及影視產品製造及分銷',
            'Motion picture projection ': '電影放影',
            'Movers': '搬屋公司',
            'Museums activities and preservation of historical sites and buildings': '博物館及文物保護',
            'National post activities': '國際郵遞服務',
            'News agency activities e.g. furnishing news pictures and features to the media and activities of journalists': '報館及新聞機構',
            'Nominee companies': '代理人公司',
            'Non-Chinese Restaurants': '非中式餐館',
            'Non-life insurance': '非人壽保險',
            'Non-scheduled air transport, including transport via space': '非定時航空運輸包括太空運輸',
            'Operation of slaughterhouses ': '經營屠宰場',
            'Operation of terminal facilities ': '貨柜設施運作',
            'Operation of transport infrastructure e.g. roads, car parks, etc.': '交通基本設施運作如道路、停車場等',
            'Operation of web search portals and internet entertainment sites': '入門搜尋網站運作及互聯網娛樂網址',
            'Other business activities n.e.c.': '其他商業活動',
            'Other credit granting by institutions not involved in monetary intermediation': '其他信貸公司不附屬於中介金融機構',
            'Other education': '其他教育',
            'Other entertainment and recreational activities n.e.c. ': '其他演藝娛樂及消遣行業',
            'Other financial intermediation n.e.c., except insurance and pension funding ': '其他中介金融機構除保險及退休金管理',
            'Other human health activities n.e.c.': '其他人類健康醫療行業',
            'Other manufacturing n.e.c.': '其他製造業',
            'Other membership organizations n.e.c.': '其他會員組織團體',
            'Other mining and quarrying n.e.c.': '其他採礦行業',
            'Other monetary intermediation n.e.c.': '其他金融機構',
            'Other non-scheduled passenger land transport ': '其他陸路非定時乘客運輸',
            'Other non-store retail sale, such as by door-to-door salespersons, vending machines or via internet auctions': '非門市零售如逐戶推銷人員、自動販賣機或網上拍賣等',
            'Other publishing, including publishing of photos, postcards, posters, reproduction of works of art, etc': '其他出版如相片、明信片、海報及仿藝術品印刷',
            'Other retail sale in non-specialized stores': '非專門店零售士多',
            'Other retail sale in specialized stores, n.e.c.': '其他零售專門店',
            'Other scheduled passenger land transport': '其他陸路定時乘客運輸',
            'Other service activities n.e.c. ': '其他服務行業包括家庭傭工',
            'Other software consultancy and supply': '其他軟件顧問及供應',
            'Other supporting transport activities n.e.c.': '其他運輸活動支援',
            'Other transport agencies n.e.c.': '其他交通運輸代理商',
            'Other wholesale/ trading n.e.c.': '其他零售及批發',
            'Packaging activities': '包裝服務',
            'Pension funding': '退休金管理',
            'Photographic activities ': '攝影及有關之行業',
            'Physiotherapy ': '物理治療',
            'Political organizations': '政治團體',
            'Preservation and preparation of meat and meat products ': '肉類及肉類產品製造及保鮮',
            'Primary and pre-primary education': '小學及以下之教育',
            'Printing for others on a fee or contract basis': '以收費或合約形式經營印刷',
            'Processing and preserving of fish and fish products': '魚類及魚類產品處理及保鮮',
            'Processing and preserving of fruit and vegetables': '蔬果處理及保鮮',
            'Processing of nuclear fuel': '核子燃料處理',
            'Production and casting of other non-ferrous metals including copper, lead, chrome, manganese, zinc, aluminium, nickel, tin or other non-ferrous base metals and alloys of such metals': '鑄造不含鐵金屬包括銅、鉛、鉻、錳、鋅、鋁、鎳、錫及合金等',
            'Production of radio and television programmes': '電台及電視節目製作',
            'Production, collection and distribution of electricity': '電力生產、收集及供應',
            'Production, collection and distribution of steam and hot water for heating, power and other purposes': '水蒸氣、熱水加熱及能量生產、收集及供應',
            'Professional organizations': '專業團體',
            'Property development- industrial': '物業發展 - 工業',
            'Property development- office/ shop': '物業發展 - 寫字樓／商舖',
            'Property development- other': '物業發展 - 其他',
            'Property development- residential': '物業發展 - 住宅',
            'Property holding for rental – industrial': '出租自置物業 - 工業',
            'Property holding for rental – office/ shop': '出租自置物業 - 寫字樓／商舖',
            'Property holding for rental – residential': '出租自置物業 - 住宅',
            'Property holding for self-use': '物業發展 - 自用',
            'Publishing of books, brochures, musical books and other publications': '書籍出版',
            'Publishing of newspapers, journals and periodicals': '報紙、雜誌及周刋出版',
            'Publishing of records and other recorded audio media': '唱片及其他錄音媒體出版',
            'Real estate activities on a fee or contract basis': '以收費或合約經營地產行業（地產代理／拍賣）',
            'Real estate activities with own or leased property, n.e.c.': '與租用／自置物業有關之行業',
            'Recycling of metal waste and scrap': '金屬廢料循環再做',
            'Recycling of non-metal waste and scrap': '非金屬廢料循環再做',
            'Religious organizations': '宗教團體',
            'Renting of air transport equipment without operator': '航空交通工具出租不連操作人員',
            'Renting of construction or demolition equipment with operator': '出租建設和拆毀的裝備',
            'Renting of land transport equipment without driver/ operator ': '陸上交通工具出租不連司機',
            'Renting of other machinery and equipment ': '其他機械設備出租',
            'Renting of personal and household goods n.e.c.': '私人及家居物品出租',
            'Renting of water transport equipment without operator': '水上交通工具出租不連操作人員',
            'Repair and alteration of personal and household goods': '個人及家居用品維修及改裝',
            'Reproduction of recorded media- including records, audio and video tapes and computer software- from master copies': '生產影音及軟件包括唱片、錄影帶及軟件拷貝',
            'Research and development ': '研究及發展',
            'Restaurants, bars and canteens n.e.c.': '餐廳、酒吧及飯堂',
            'Restricted licensed banks': '有限制持牌銀行附屬金融機構',
            'Retail sale by mail order via mail order houses, television, radio, telephone or internet': '網上零售，經電話、電報認購或郵購',
            'Retail sale in non-specialized stores with food, beverages or tobacco predominating': '供應食品、飲料及香煙之非專門店零售士多',
            'Retail sale of antiques': '古物零售店',
            'Retail sale of articles of clothing, fur and clothing accessories': '成衣、皮草及製衣配件零售',
            'Retail sale of articles of lighting': '照明設備零售',
            'Retail sale of automotive fuel': '汽車燃料零售',
            'Retail sale of bakery products': '麵包零售',
            'Retail sale of books, newspapers, magazines and stationery': '書簿、報紙、雜誌及周刋零售',
            'Retail sale of dried seafood': '海產乾貨零售',
            'Retail sale of flowers, plants, seeds and fertilizers ': '花草、植物、種子及肥料零售',
            'Retail sale of food, beverages and tobacco in specialized stores n.e.c.': '供應食品、飲料及香煙之專門店',
            'Retail sale of footwear, leather goods and travel accessories of leather and leather substitutes': '鞋類、皮革製品，旅行用具及代用品零售',
            'Retail sale of games and toys': '遊戲及玩具零售',
            'Retail sale of hardware, paints and glass': '鐵器、油漆及玻璃零售',
            'Retail sale of household appliances, radio and television equipment and other household audio/ visual equipment ': '家居電器零售包括收音機、電視機及影音視聽器材',
            'Retail sale of household articles of textile materials, including curtains ': '布製家居用品零售包括窗廉',
            'Retail sale of household furniture': '家居傢俬零售',
            'Retail sale of household utensils, cutlery, crockery and kitchenware ': '器皿、餐具刀叉、陶器及廚房用具零售',
            'Retail sale of meat, fruit, vegetables, fishery (except dried seafood) and eggs': '肉類、生果、蔬菜、魚類（不包括乾貨）及雞蛋零售',
            'Retail sale of musical instruments and scores ': '音樂器材及樂譜零售',
            'Retail sale of musical records, audio/ visual tapes, CDs and cassettes ': '唱片、錄音及錄影帶、CD及卞式錄音帶零售',
            'Retail sale of office equipment, computers and non-customized software': '辦公室用品、電腦及非定做軟件零售',
            'Retail sale of optical products': '光學產品零售',
            'Retail sale of perfumery, cosmetic and toilet articles ': '香水、美容化粧及衛生用品零售',
            'Retail sale of pet animals and food': '寵物及其食物零售',
            'Retail sale of pharmaceutical and medical goods': '醫藥製品零售',
            'Retail sale of photographic and precision equipment': '攝影精密器材零售',
            'Retail sale of second-hand goods in stores n.e.c.': '二手貨物零售店',
            'Retail sale of sports goods ': '運動器材用品零售',
            'Retail sale of sugar, chocolate, confectionery, snacks and non-alcoholic beverages': '糖果、朱古力、糕餅、零食、西餅麵包及非酒精飲品零售',
            'Retail sale of telecommunication equipment': '電訊器材零售',
            'Retail sale of textiles, knitting yarn, fabrics and basic materials for rug, tapestry or embroidery making': '紡織、布織、紡織品、地毯、繡帷、刺繡原料零售',
            'Retail sale of wallpaper, floor coverings, carpets and rugs': '牆紙、地板及地毯零售',
            'Retail sale of watches, clocks and jewellery': '手錶、時鐘及珠寶零售',
            'Retail sale of wine and liquors': '萄葡酒及酒精飲品零售',
            'Retail sale via stalls and markets': '街市攤檔零售',
            'Sale and servicing of motor vehicles and motorcycles n.e.c.': '汽車及電單車零售及維修',
            'Sale and servicing of motorcycles and related parts and accessories': '電單車及零件出售及維修',
            'Sale of motor vehicle parts and accessories': '汽車零件出售',
            'Sale of new and used right-hand drive motor vehicles- retail': '全新及二手右軚車零售',
            'Sale of new and used right-hand drive motor vehicles- wholesale': '全新及二手右軚車批發',
            'Sawmilling and planing of wood': '林業及鋸木',
            'Scheduled air transport': '定時航空運輸',
            'Sea and coastal water transport ': '海上及沿海運輸',
            'Securities custodians': '証券託管',
            'Security dealing in futures by broker on behalf of customers': '証券買賣-期貨經紀',
            'Security dealing in options by broker on behalf of customers': '証券買賣-期權經紀',
            'Security dealing in shares by broker on behalf of customers': '証券買賣-經紀',
            'Security dealing n.e.c.': '証券買賣',
            'Service activities incidental to oil and gas extraction excluding surveying, provided on a fee or contract basis': '附屬於提煉石油和天然氣的以合約形式或收費業務（勘測除外）',
            'Service activities related to printing, including bookbinding and production of prepared printing plates ': '印刷服務包括釘裝服務及製版等',
            'Servicing of motor vehicles': '汽車維修服務',
            'Sewage and refuse disposal, sanitation and similar activities': '污水及廢物處理，衛生設施及同類服務',
            'Site preparation, including demolition of buildings, blasting, test drilling, and landfill': '地盤準備工程包括拆毀、爆破、鑽探及填土',
            'Social work activities provided by government offices': '社會工作服務 - 政府提供',
            'Social work activities provided by private or charitable organizations': '社會工作服務 - 私人機構提供',
            'Software publishing': '軟件出版發行',
            'Sporting activities, including the operation of the facilities in which various sports are performed': '體育及康樂組織',
            'Storage and warehousing': '儲存及貨倉',
            'Tanning and dressing of leather': '皮革加工',
            'Technical and vocational secondary education': '工藝及職業中學教育',
            'Technical testing and analysis of all types of materials and products ': '各類材料及產品技術測試及分析',
            'Telecommunications, n.e.c.': '電訊服務',
            'Trade unions': '勞工組織',
            'Trading of agricultural raw materials and live animals': '農產品原料及生禽零售',
            'Trading of beer, wine and liquors': '啤酒、萄葡酒及酒類零售',
            'Trading of blank audio and video tapes and diskettes, and magnetic and optical disks': '錄音帶、錄影帶、磁碟及光碟零售',
            'Trading of clothing and accessories ': '成衣及配件零售',
            'Trading of coffee, tea, cocoa and spices': '咖啡、紅茶、可可及香料零售',
            'Trading of computers, computer peripheral equipment and software': '電腦、電腦週邊產品及軟件零售',
            'Trading of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.': '建築材料、五金、水管、暖氣設備及供應零售',
            'Trading of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.': '家居電動及電子器具零售（如雪柜、電燈、收音機及電視器具等）',
            'Trading of dried seafood': '海產乾貨之零售',
            'Trading of edible oils and fats': '糧油食品零售',
            'Trading of electrical material': '電工材料零售',
            'Trading of electronic and telecommunications parts and equipment n.e.c.': '電子及電信零件及設備零售',
            'Trading of electronic valves and tubes': '電子真空管零售',
            'Trading of food, beverages and tobacco n.e.c.': '食物、飲料及香煙零售',
            'Trading of footwear': '鞋類零售',
            'Trading of games and toys, musical instruments, sports goods and bicycles ': '遊戲及玩具、音樂器材、運動器材及單車零售',
            'Trading of general purpose machinery and machine tools': '特別用途機器及工具零售',
            'Trading of gold and other precious metals ': '黃金及其他貴金屬零售',
            'Trading of household ceramic ware, glassware, cutlery and kitchenware': '家居陶瓷器皿、玻璃器皿、餐具刀叉及廚房器皿零售',
            'Trading of household furniture': '家用傢俬零售',
            'Trading of industrial chemicals, fertilizers and agrochemical products': '化工材料、肥料及農藥產品零售',
            'Trading of leather goods (including travel accessories) and paper products': '皮革製品（包括旅行用具）及紙類產品零售',
            'Trading of machinery for textile, apparel and leather production': '紡織、製衣及皮革製造機器零售',
            'Trading of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ': '肉類、生果、蔬菜、魚類（不包括乾貨）、雞蛋及乳類產品零售',
            'Trading of medical and precision instruments': '醫療及精密儀器零售',
            'Trading of metal and non-metal waste and scrap and materials for recycling': '循環再用金屬及非金屬廢料零售',
            'Trading of office furniture': '辦公室傢俬零售',
            'Trading of office machinery and equipment, except computers and computer peripheral equipment': '辦公室機器設備零售，不包括電腦及電腦週邊產品',
            'Trading of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ': '含鐵及不含鐵金屬零售，包括鐵礦、金屬原料及半製成金屬產品',
            'Trading of other household goods, n.e.c.': '其他家居用品零售',
            'Trading of other intermediate products, waste and scrap n.e.c.': '廢料零售',
            'Trading of other machinery, equipment and supplies n.e.c.': '其他機器及工具設備零售',
            'Trading of other special purpose machinery ': '特別用途機器零售',
            'Trading of paint and varnish': '油漆及顏料零售',
            'Trading of pharmaceutical and medical goods, perfumerie, cosmetics and soaps': '製藥、醫療用品、香水、美容化妝品及肥皂零售',
            'Trading of photographic and optical goods': '攝影及光學產品零售',
            'Trading of plastic materials in primary forms': '塑膠原料零售',
            'Trading of precious stones': '寶石零售',
            'Trading of pulp and paper in bulk': '紙張及紙槳零售',
            'Trading of rice and cereals': '稻米及榖物零售',
            'Trading of rubber': '橡膠原料零售',
            'Trading of sanitary equipment and sanitary installation equipment': '厠所衛生設備及裝置工具零售',
            'Trading of semi-conductor devices, microchips, integrated circuits and printed circuits ': '半導體裝置、電腦晶片、積體電路板及印刷電路板零售',
            'Trading of solid, liquid and gaseous fuels and related products': '固體、液體、氣體燃料及有關產品零售',
            'Trading of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages': '肉類、生果、蔬菜、魚類（不包括乾貨）、雞蛋及乳類產品零售',
            'Trading of telephone and communications equipment': '電話及電話設備零售',
            'Trading of textile fibres': '紡織材料零售',
            'Trading of textiles, clothing and footwear n.e.c.': '紗線、紡織及家用麻製品零售',
            'Trading of tobacco products': '香煙零售',
            'Trading of tools, hardware, fittings and fixtures': '工具、五金、裝修及固定裝置零售',
            'Trading of transport equipment except motor vehicles, motorcycles and bicycles': '交通工具零售，不包括汽車、電單車及單車',
            'Trading of watches, clocks and jewellery': '手錶、時鐘及珠寶零售',
            'Trading of wood, sand, stone, clay, cement, glass and related construction materials ': '木、沙、石、泥土、水泥、玻璃及其他建築材料零售',
            'Trading of yarn, fabrics and household linens': '紗線、紡織及家用麻製品零售',
            'Transport via pipelines, including transport of gases, liquids, slurry and other commodities via pipelines': '管道運輸包括運送氣體、液體、泥槳及其他經管道運送之貨品',
            'Transport via railways': '鐵路交通',
            'Treatment and coating of metals; general mechanical engineering on a fee or contract basis': '鐵外層處理及塗料；一般收費或合約形式之機械工程',
            'Trusts/ Trustees': '信託公司',
            'Veterinary activities': '獸醫',
            'Washing, and (dry-) cleaning of textile and fur products': '洗衣、乾洗成衣及皮衣',
            'Wholesale and retail of left-hand drive motor vehicles': '全新及二手左軚車批發及零售',
            'Wholesale of agricultural raw materials and live animals': '農產品原料及生禽批發',
            'Wholesale of beer, wine and liquors': '啤酒、萄葡酒及酒類批發',
            'Wholesale of blank audio and video tapes and diskettes, and magnetic and optical disks': '錄音帶、錄影帶、磁碟及光碟批發',
            'Wholesale of clothing and accessories ': '成衣及附屬用品批發',
            'Wholesale of coffee, tea, cocoa and spices': '咖啡、紅茶、可可及香料批發',
            'Wholesale of computers, computer peripheral equipment and software': '電腦、電腦週邊產品及軟件批發',
            'Wholesale of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.': '建築材料、五金、水管、暖氣設備及供應批發',
            'Wholesale of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.': '家居電力及電子器具批發（如雪柜、電燈、收音機及電視器具等）',
            'Wholesale of dried seafood': '晒乾之海產批發',
            'Wholesale of edible oils and fats': '糧油食品批發',
            'Wholesale of electrical material': '電工材料批發',
            'Wholesale of electronic and telecommunications parts and equipment n.e.c.': '電子及電信零件及設備批發',
            'Wholesale of electronic valves and tubes': '電子真空管批發',
            'Wholesale of food, beverages and tobacco n.e.c.': '食物、飲料及香煙批發',
            'Wholesale of footwear': '鞋類批發',
            'Wholesale of games and toys, musical instruments, sports goods and bicycles ': '遊戲及玩具、音樂器材、運動器材及單車批發',
            'Wholesale of general purpose machinery and machine tools': '特別用途機器及工具批發',
            'Wholesale of gold and other precious metals ': '黃金及其他貴金屬批發',
            'Wholesale of household ceramic ware, glassware, cutlery and kitchenware': '家居陶瓷器皿、玻璃器皿、餐具刀叉及廚房器皿批發',
            'Wholesale of household furniture': '家居傢俬批發',
            'Wholesale of industrial chemicals, fertilizers and agrochemical products': '化工材料、肥料及農藥產品批發',
            'Wholesale of leather goods (including travel accessories) and paper products': '皮革製品(包括旅行用具)及紙類產品批發',
            'Wholesale of machinery for textile, apparel and leather production': '紡織、製衣及皮革製造機器批發',
            'Wholesale of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ': '肉類、生果、蔬菜、魚類（不包括乾貨）、雞蛋及乳類產品批發',
            'Wholesale of medical and precision instruments': '醫療及精密儀器批發',
            'Wholesale of metal and non-metal waste and scrap and materials for recycling': '循環再用金屬及非金屬廢料批發',
            'Wholesale of office furniture': '辦公室傢俬批發',
            'Wholesale of office machinery and equipment, except computers and computer peripheral equipment': '辦公室機器設備批發，不包括電腦及電腦週邊產品',
            'Wholesale of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ': '含鐵及不含鐵金屬批發，包括鐵礦、金屬原料及半製成金屬產品',
            'Wholesale of other household goods, n.e.c.': '其他家居用品批發',
            'Wholesale of other intermediate products, waste and scrap n.e.c.': '廢料批發',
            'Wholesale of other machinery, equipment and supplies n.e.c.': '其他機器及工具設備批發',
            'Wholesale of other special purpose machinery': '其他特殊用途機械批發',
            'Wholesale of paint and varnish': '油漆及顏料批發',
            'Wholesale of pharmaceutical and medical goods, perfumerie, cosmetics and soaps': '製藥、醫療用品、香水、美容化妝品及肥皂批發',
            'Wholesale of photographic and optical goods': '攝影及光學產品批發',
            'Wholesale of plastic materials in primary forms': '塑膠原料批發',
            'Wholesale of precious stones': '寶石批發',
            'Wholesale of pulp and paper in bulk': '紙張及紙槳批發',
            'Wholesale of rice and cereals': '稻米及榖物批發',
            'Wholesale of rubber': '橡膠原料批發',
            'Wholesale of sanitary equipment and sanitary installation equipment': '厠所衛生設備及裝置工具批發',
            'Wholesale of semi-conductor devices, microchips, integrated circuits and printed circuits ': '半導體裝置、電腦晶片、積體電路板及印刷電路板批發',
            'Wholesale of solid, liquid and gaseous fuels and related products': '固體、液體、氣體燃料及有關產品批發',
            'Wholesale of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages': '糖果、朱古力、糕餅、零食、西餅麵包及非酒精飲品批發',
            'Wholesale of telephone and communications equipment': '電話及電話設備批發',
            'Wholesale of textile fibres': '紡織材料批發',
            'Wholesale of textiles, clothing and footwear n.e.c.': '紡織品、成衣及鞋類批發',
            'Wholesale of tobacco products': '香煙批發',
            'Wholesale of tools, hardware, fittings and fixtures': '工具、五金、裝修及固定裝置批發',
            'Wholesale of transport equipment except motor vehicles, motorcycles and bicycles': '交通工具批發，不包括汽車、電單車及單車',
            'Wholesale of watches, clocks and jewellery': '手錶、時鐘及珠寶批發',
            'Wholesale of wood, sand, stone, clay, cement, glass and related construction materials ': '木、沙、石、泥土、水泥、玻璃及其他建築材料批發',
            'Wholesale/ Trading on a fee or contract basis, such as commission agents, commodity brokers and auctioneers ': '以合約或收費形式經營零售及批發，例如收佣金之代理人、商品經紀及拍賣員等',

        },

        employmentStatusList: [
            {
                value: 'SELF_EMPLOYED',
                label: '自僱'
            },
            {
                value: 'FULL_TIME_EMPLOYMENT',
                label: '全職'
            },
            {
                value: 'PART_TIME_EMPLOYMENT',
                label: '兼職'
            },
            {
                value: 'UNEMPLOYED',
                label: '無業'
            },
            {
                value: 'STUDENT',
                label: '學生'
            },
            {
                value: 'HOMEMAKER',
                label: '持家者'
            },
            {
                value: 'RETIRED',
                label: '退休'
            }
        ],

        residentialReasonList: [
            {value: 'A', label: "本人為現居住地址國家／地區的學生，但居住時間不足以成為當地稅務居民。"},
            {value: 'B', label: "本人於現居住地址國家／地區工作，但居住時間不足以成為當地稅務居民。"},
            {value: 'C', label: "本人為派駐於現居住地址國家／地區的外交官或軍人。"},
            {value: 'D', label: "本人於近期移居至現居住地址國家／地區，但尚未成為當地稅務居民"},
            {value: 'E', label: '上述選項皆不符合'}
        ],
        correspondenceReasonList: [
            {value: 'A', label: "我的通訊地址為我的顧問、會計師或律師的地址。"},
            {value: 'B', label: "我的通訊地址為我的親屬的地址。"},
            {value: 'C', label: "我的通訊地址為我的工作／公司的地址。"},
            {value: 'D', label: "我的通訊地址為我於度假時所居住的地址。"},
            {value: 'E', label: '上述選項皆不符合。'}
        ],
    },
    en: {
        occupation: [
            { value: 'Accountant / auditor ', label: 'Accountant / auditor ' },
            { value: 'Architect', label: 'Architect' },
            { value: 'Artist/performer/producer/athlete', label: 'Artist/performer/producer/athlete' },
            { value: 'Business owner/partner/director/senior management', label: 'Business owner/partner/director/senior management' },
            { value: 'Chef/server', label: 'Chef/server' },
            { value: 'Clerical/admin/secretarial', label: 'Clerical/admin/secretarial' },
            { value: 'Construction worker', label: 'Construction worker' },
            { value: 'Consultant', label: 'Consultant' },
            { value: 'Data processer /IT manager', label: 'Data processer /IT manager' },
            { value: 'Domestic helper/cleaner', label: 'Domestic helper/cleaner' },
            { value: 'Driver', label: 'Driver' },
            { value: 'Engineer ', label: 'Engineer ' },
            { value: 'Farmer/fisherperson/forester', label: 'Farmer/fisherperson/forester' },
            { value: 'Financial Controller', label: 'Financial Controller' },
            { value: 'Firefighter', label: 'Firefighter' },
            { value: 'Flight attendant/airport ground crew/pilot', label: 'Flight attendant/airport ground crew/pilot' },
            { value: 'Immigration services officer', label: 'Immigration services officer' },
            { value: 'Inland revenue officer ', label: 'Inland revenue officer ' },
            { value: 'Insurance agent/brokers', label: 'Insurance agent/brokers' },
            { value: 'Investment / fund manager', label: 'Investment / fund manager' },
            { value: 'Lawyer/solicitor', label: 'Lawyer/solicitor' },
            { value: 'Marketing', label: 'Marketing' },
            { value: 'Medical professional ', label: 'Medical professional ' },
            { value: 'Military', label: 'Military' },
            { value: 'Other licensed professional', label: 'Other licensed professional' },
            { value: 'Other manual/ skilled worker ', label: 'Other manual/ skilled worker ' },
            { value: 'Other office worker/semi-professional', label: 'Other office worker/semi-professional' },
            { value: 'Other service worker (e.g. hospitality, personal services)', label: 'Other service worker (e.g. hospitality, personal services)' },
            { value: 'Police', label: 'Police' },
            { value: 'Politician/ambassador/consul-general', label: 'Politician/ambassador/consul-general' },
            { value: 'Postal worker', label: 'Postal worker' },
            { value: 'Prison/correctional services officer', label: 'Prison/correctional services officer' },
            { value: 'Property agent', label: 'Property agent' },
            { value: 'Salesperson', label: 'Salesperson' },
            { value: 'Security guard', label: 'Security guard' },
            { value: 'Senior government/judicial official', label: 'Senior government/judicial official' },
            { value: 'Social worker', label: 'Social worker' },
            { value: 'Teacher/principal/lecturer/professor', label: 'Teacher/principal/lecturer/professor' },
            { value: 'Traffic warden', label: 'Traffic warden' },
        ],
        employmentRoleList: [
            { value: 'Business Owner', label: 'Business owner' },
            { value: 'Employee', label: 'Employee' },
            { value: 'Key Controller', label: 'Key controller' },
            { value: 'Sole Trader', label: 'Sole trader' },
        ],
        industryList: {
            'Accounting, book-keeping and auditing activities; tax consultancy': 'Accounting, book-keeping and auditing activities; tax consultancy',
            'Activities auxiliary to financial intermediation n.e.c., except to insurance and pension funding ': 'Activities auxiliary to financial intermediation n.e.c., except to insurance and pension funding ',
            'Activities auxiliary to insurance and pension funding': 'Activities auxiliary to insurance and pension funding',
            'Activities normally carried out by the public administration': 'Activities normally carried out by the public administration',
            'Activities of travel agencies and tour operators; tourist assistance activities n.e.c.': 'Activities of travel agencies and tour operators; tourist assistance activities n.e.c.',
            'Administration of financial markets, including the activities of stock exchanges and exchanges for commodity futures contracts': 'Administration of financial markets, including the activities of stock exchanges and exchanges for commodity futures contracts',
            'Advertising': 'Advertising',
            'Agricultural and animal husbandry service activities, except veterinary activities': 'Agricultural and animal husbandry service activities, except veterinary activities',
            'Architectural and engineering activities and related technical consultancy, n.e.c.': 'Architectural and engineering activities and related technical consultancy, n.e.c.',
            'Architectural consulting activities ': 'Architectural consulting activities ',
            'Banks': 'Banks',
            'Bars': 'Bars',
            'Bath house, sauna & massage salons': 'Bath house, sauna & massage salons',
            'Botanical and zoological gardens and nature reserves activities': 'Botanical and zoological gardens and nature reserves activities',
            'Building and repairing of ships and boats': 'Building and repairing of ships and boats',
            'Building completion': 'Building completion',
            'Building installation n.e.c.': 'Building installation n.e.c.',
            'Building installation, including the installation in buildings of all kinds of utilities ': 'Building installation, including the installation in buildings of all kinds of utilities ',
            'Building of complete constructions or parts thereof; civil engineering, n.e.c.': 'Building of complete constructions or parts thereof; civil engineering, n.e.c.',
            'Building-cleaning and industrial-cleaning activities': 'Building-cleaning and industrial-cleaning activities',
            'Business and employers organizations': 'Business and employers organizations',
            'Business and management consultancy activities': 'Business and management consultancy activities',
            'Cargo handling': 'Cargo handling',
            'Casino and other types of gaming/gambling operation (SCC)': 'Casino and other types of gaming/gambling operation (SCC)',
            'Central banking': 'Central banking',
            'Chinese herbalists/ medical practice including acupuncture': 'Chinese herbalists/ medical practice including acupuncture',
            'Chinese Restaurants': 'Chinese Restaurants',
            'Chiropractice': 'Chiropractice',
            'Civil engineering including construction of highways, streets, bridges, tunnels, railways, sewerage systems, etc. ': 'Civil engineering including construction of highways, streets, bridges, tunnels, railways, sewerage systems, etc. ',
            'Clubs, karaoke & entertainment lounges': 'Clubs, karaoke & entertainment lounges',
            'Collection, purification and distribution of water': 'Collection, purification and distribution of water',
            'Compilation of data and provision of on-line access to proprietary databases': 'Compilation of data and provision of on-line access to proprietary databases',
            'Courier activities other than national post activities': 'Courier activities other than national post activities',
            'Custom tailoring': 'Custom tailoring',
            'Cutting, shaping and finishing of stone for use in construction, in cemeteries, on roads, as roofing and in other applications': 'Cutting, shaping and finishing of stone for use in construction, in cemeteries, on roads, as roofing and in other applications',
            'Data processing ': 'Data processing ',
            'Database activities and on-line distribution of electronic content n.e.c.': 'Database activities and on-line distribution of electronic content n.e.c.',
            'Dental practice': 'Dental practice',
            'Deposit-taking companies': 'Deposit-taking companies',
            'Dramatic arts, music and other arts activities': 'Dramatic arts, music and other arts activities',
            'Dressing and dyeing of fur; manufacture of articles of fur, n.e.c.': 'Dressing and dyeing of fur; manufacture of articles of fur, n.e.c.',
            'Dressing and dyeing of furskins': 'Dressing and dyeing of furskins',
            'Engineering and related technical consultancy activities ': 'Engineering and related technical consultancy activities ',
            'Export credit agencies': 'Export credit agencies',
            'Extra-territorial organizations and bodies ': 'Extra-territorial organizations and bodies ',
            'Extraction of crude petroleum and natural gas': 'Extraction of crude petroleum and natural gas',
            'Factoring institutions': 'Factoring institutions',
            'Farming of animals and livestock': 'Farming of animals and livestock',
            'Fast Food Shops': 'Fast Food Shops',
            'Finance houses': 'Finance houses',
            'Financial leasing': 'Financial leasing',
            'Finishing of textiles by such operations as dyeing or printing': 'Finishing of textiles by such operations as dyeing or printing',
            'Fishing, aquaculture and service activities incidental to fishing': 'Fishing, aquaculture and service activities incidental to fishing',
            'Fixed telecommunications network services (FTNS) operators': 'Fixed telecommunications network services (FTNS) operators',
            'Forestry, logging and related service activities': 'Forestry, logging and related service activities',
            'Forging, pressing, stamping and roll-forming of metal; powder metallurgy': 'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
            'Freight forwarders': 'Freight forwarders',
            'Freight transport by road': 'Freight transport by road',
            'Fund manager (as agent re named fund)': 'Fund manager (as agent re named fund)',
            'Fund manager (as agent re unnamed fund)': 'Fund manager (as agent re unnamed fund)',
            'Fund manager (as hedge fund)': 'Fund manager (as hedge fund)',
            'Fund manager (as principle)': 'Fund manager (as principle)',
            'Funeral and related activities': 'Funeral and related activities',
            'General construction including new works, additions and alterations': 'General construction including new works, additions and alterations',
            'General secondary education': 'General secondary education',
            'Growing of crops combined with farming of animals (mixed farming)': 'Growing of crops combined with farming of animals (mixed farming)',
            'Growing of crops, market gardening, horticulture': 'Growing of crops, market gardening, horticulture',
            'Hairdressing and other beauty treatment': 'Hairdressing and other beauty treatment',
            'Hardware consultancy': 'Hardware consultancy',
            'Higher education, including post-secondary sub-degree level education and university education': 'Higher education, including post-secondary sub-degree level education and university education',
            'Holding of raw land for sale or future development': 'Holding of raw land for sale or future development',
            'Hospitals': 'Hospitals',
            'Hotels; camping sites and other provision of short-stay accommodation ': 'Hotels; camping sites and other provision of short-stay accommodation ',
            'Hunting, trapping and game propagation including related service activities': 'Hunting, trapping and game propagation including related service activities',
            'Inland water transport': 'Inland water transport',
            'Installation of illumination and signaling systems for roads, railways, airports, harbours, etc.': 'Installation of illumination and signaling systems for roads, railways, airports, harbours, etc.',
            'Internet access provision': 'Internet access provision',
            'Investigation and security activities ': 'Investigation and security activities ',
            'Investment in securities (proprietary holdings)': 'Investment in securities (proprietary holdings)',
            'Knitting or crocheting of wearing apparel directly to shape ': 'Knitting or crocheting of wearing apparel directly to shape ',
            'Labour recruitment and provision of personnel ': 'Labour recruitment and provision of personnel ',
            'Legal activities': 'Legal activities',
            'Library and archives activities': 'Library and archives activities',
            'Licensed banks': 'Licensed banks',
            'Life insurance': 'Life insurance',
            'Maintenance and repair of office, accounting and computing machinery': 'Maintenance and repair of office, accounting and computing machinery',
            'Manufacture and casting of precious metals including gold, silver, and metals of the platinum group, including production of clad precious metals': 'Manufacture and casting of precious metals including gold, silver, and metals of the platinum group, including production of clad precious metals',
            'Manufacture and distribution of arms, other military products (SCC)': 'Manufacture and distribution of arms, other military products (SCC)',
            'Manufacture of accumulators, primary cells and primary batteries': 'Manufacture of accumulators, primary cells and primary batteries',
            'Manufacture of aircraft and spacecraft': 'Manufacture of aircraft and spacecraft',
            'Manufacture of alcoholic drinks including non-alcoholic beer and wine': 'Manufacture of alcoholic drinks including non-alcoholic beer and wine',
            'Manufacture of all kinds of clothing accessories ': 'Manufacture of all kinds of clothing accessories ',
            'Manufacture of apparatus for line telephony and line telegraphy': 'Manufacture of apparatus for line telephony and line telegraphy',
            'Manufacture of articles of cork, straw and plaiting materials': 'Manufacture of articles of cork, straw and plaiting materials',
            'Manufacture of articles of fur such as rugs, unstuffed pouffes, etc.': 'Manufacture of articles of fur such as rugs, unstuffed pouffes, etc.',
            'Manufacture of articles of leather or composition leather n.e.c.': 'Manufacture of articles of leather or composition leather n.e.c.',
            'Manufacture of artificial flowers and trees': 'Manufacture of artificial flowers and trees',
            'Manufacture of bakery products': 'Manufacture of bakery products',
            'Manufacture of basic chemicals ': 'Manufacture of basic chemicals ',
            'Manufacture of basic iron and steel': 'Manufacture of basic iron and steel',
            'Manufacture of bicycles and invalid carriages': 'Manufacture of bicycles and invalid carriages',
            "Manufacture of builders' carpentry and joinery, including manufacture of wooden goods primarily for the construction industryManufacture of builders' carpentry and joinery, including manufacture of wooden goods primarily for the construction industry ": "Manufacture of builders' carpentry and joinery, including manufacture of wooden goods primarily for the construction industry",
            'Manufacture of carpets and rugs': 'Manufacture of carpets and rugs',
            'Manufacture of cathode tubes or valves e.g. television picture tubes, television camera tubes, etc.': 'Manufacture of cathode tubes or valves e.g. television picture tubes, television camera tubes, etc.',
            'Manufacture of cement, lime and plaster and articles thereof, such as boards, ready-mixed concrete, articles of asbestos-cement, etc.': 'Manufacture of cement, lime and plaster and articles thereof, such as boards, ready-mixed concrete, articles of asbestos-cement, etc.',
            'Manufacture of cocoa, chocolate and sugar confectionery': 'Manufacture of cocoa, chocolate and sugar confectionery',
            'Manufacture of coke oven products ': 'Manufacture of coke oven products ',
            'Manufacture of containers made of non-corrugated paper e.g. cartons, bags, etc.': 'Manufacture of containers made of non-corrugated paper e.g. cartons, bags, etc.',
            'Manufacture of cordage, rope, twine and netting': 'Manufacture of cordage, rope, twine and netting',
            'Manufacture of corrugated paper and corrugated boxes': 'Manufacture of corrugated paper and corrugated boxes',
            'Manufacture of cutlery, hand tools and general hardware': 'Manufacture of cutlery, hand tools and general hardware',
            'Manufacture of dairy products': 'Manufacture of dairy products',
            'Manufacture of electric lamps and lighting equipment': 'Manufacture of electric lamps and lighting equipment',
            'Manufacture of electric motors, generators and transformers ': 'Manufacture of electric motors, generators and transformers ',
            'Manufacture of electricity distribution and control apparatus such as switches, fuses, lightning arresters, voltage limiters, surge suppressors, plugs, junction boxes, etc': 'Manufacture of electricity distribution and control apparatus such as switches, fuses, lightning arresters, voltage limiters, surge suppressors, plugs, junction boxes, etc',
            'Manufacture of electronic components n.e.c. e.g. diodes, transistors and similar semi-conductor devices, electrical capacitors or condensers, resistors, LCD displays, etc.': 'Manufacture of electronic components n.e.c. e.g. diodes, transistors and similar semi-conductor devices, electrical capacitors or condensers, resistors, LCD displays, etc.',
            'Manufacture of electronic games e.g. handheld electronic games, video games, etc., and toy musical instruments': 'Manufacture of electronic games e.g. handheld electronic games, video games, etc., and toy musical instruments',
            'Manufacture of electronic toys, e.g. radio-controlled/ infra-red controlled toy vehicles, educational toys, etc.': 'Manufacture of electronic toys, e.g. radio-controlled/ infra-red controlled toy vehicles, educational toys, etc.',
            'Manufacture of finished plastic products ': 'Manufacture of finished plastic products ',
            'Manufacture of footwear – leather uppers': 'Manufacture of footwear – leather uppers',
            'Manufacture of footwear – rubber or plastic uppers': 'Manufacture of footwear – rubber or plastic uppers',
            'Manufacture of footwear – textile uppers': 'Manufacture of footwear – textile uppers',
            'Manufacture of footwear, n.e.c.': 'Manufacture of footwear, n.e.c.',
            'Manufacture of furniture, including mattresses': 'Manufacture of furniture, including mattresses',
            'Manufacture of games and toys, n.e.c.': 'Manufacture of games and toys, n.e.c.',
            'Manufacture of gas; distribution of gaseous fuels through mains': 'Manufacture of gas; distribution of gaseous fuels through mains',
            'Manufacture of general purpose machinery and parts thereof': 'Manufacture of general purpose machinery and parts thereof',
            'Manufacture of glass and glass products': 'Manufacture of glass and glass products',
            'Manufacture of grain mill products, starches and starch products, and prepared animal feeds': 'Manufacture of grain mill products, starches and starch products, and prepared animal feeds',
            'Manufacture of hosiery, including socks, tights and panty hose': 'Manufacture of hosiery, including socks, tights and panty hose',
            'Manufacture of household appliances n.e.c. ': 'Manufacture of household appliances n.e.c. ',
            'Manufacture of instruments and appliances for measuring, checking, testing, navigating and other purposes, ': 'Manufacture of instruments and appliances for measuring, checking, testing, navigating and other purposes, ',
            'Manufacture of insulated wire and cable': 'Manufacture of insulated wire and cable',
            'Manufacture of integrated circuits': 'Manufacture of integrated circuits',
            'Manufacture of jewellery and related articles': 'Manufacture of jewellery and related articles',
            'Manufacture of knitted or crocheted articles n.e.c.': 'Manufacture of knitted or crocheted articles n.e.c.',
            'Manufacture of knitted or crocheted fabrics': 'Manufacture of knitted or crocheted fabrics',
            'Manufacture of luggage, handbags and the like ': 'Manufacture of luggage, handbags and the like ',
            'Manufacture of macaroni, noodles, couscous and similar farinaceous products': 'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
            'Manufacture of machine-tools and parts and accessories': 'Manufacture of machine-tools and parts and accessories',
            'Manufacture of machinery and equipment n.e.c.': 'Manufacture of machinery and equipment n.e.c.',
            'Manufacture of machinery for textile, apparel and leather production': 'Manufacture of machinery for textile, apparel and leather production',
            'Manufacture of man-made fibres ': 'Manufacture of man-made fibres ',
            'Manufacture of medical and surgical equipment and orthopedic appliances': 'Manufacture of medical and surgical equipment and orthopedic appliances',
            'Manufacture of metal structures, including bridges and bridge parts, towers, masts and columns, doors and windows and their frames, games, etc. ': 'Manufacture of metal structures, including bridges and bridge parts, towers, masts and columns, doors and windows and their frames, games, etc. ',
            'Manufacture of metal tanks, reservoirs and containers of types normally installed as fixtures ': 'Manufacture of metal tanks, reservoirs and containers of types normally installed as fixtures ',
            'Manufacture of metal toys': 'Manufacture of metal toys',
            'Manufacture of motor vehicles': 'Manufacture of motor vehicles',
            'Manufacture of motorcycles': 'Manufacture of motorcycles',
            'Manufacture of musical instruments': 'Manufacture of musical instruments',
            'Manufacture of non-structural ceramic products including articles of porcelain or china, such as tableware and sanitary fixtures': 'Manufacture of non-structural ceramic products including articles of porcelain or china, such as tableware and sanitary fixtures',
            'Manufacture of nuclear reactors and steam generators, except central heating hot water boilers': 'Manufacture of nuclear reactors and steam generators, except central heating hot water boilers',
            'Manufacture of office, accounting and computing machinery including typewriters, photo-copying apparatus, data-processing machines, etc': 'Manufacture of office, accounting and computing machinery including typewriters, photo-copying apparatus, data-processing machines, etc',
            'Manufacture of optical instruments and photographic equipment e.g. optical frames, cameras, projectors, etc.': 'Manufacture of optical instruments and photographic equipment e.g. optical frames, cameras, projectors, etc.',
            'Manufacture of other articles of paper and paperboard ': 'Manufacture of other articles of paper and paperboard ',
            'Manufacture of other chemical products n.e.c. ': 'Manufacture of other chemical products n.e.c. ',
            'Manufacture of other electrical equipment n.e.c.': 'Manufacture of other electrical equipment n.e.c.',
            'Manufacture of other fabricated metal products n.e.c.': 'Manufacture of other fabricated metal products n.e.c.',
            'Manufacture of other food products n.e.c. ': 'Manufacture of other food products n.e.c. ',
            'Manufacture of other non-metallic mineral products n.e.c.': 'Manufacture of other non-metallic mineral products n.e.c.',
            'Manufacture of other products of wood': 'Manufacture of other products of wood',
            'Manufacture of other rubber products ': 'Manufacture of other rubber products ',
            'Manufacture of other special purpose machinery chiefly employed by a particular industry or by a number of related industries': 'Manufacture of other special purpose machinery chiefly employed by a particular industry or by a number of related industries',
            'Manufacture of other textiles n.e.c.': 'Manufacture of other textiles n.e.c.',
            'Manufacture of other transport equipment n.e.c.': 'Manufacture of other transport equipment n.e.c.',
            'Manufacture of outerwear made of knitted or crocheted fabrics': 'Manufacture of outerwear made of knitted or crocheted fabrics',
            'Manufacture of outerwear made of woven fabrics': 'Manufacture of outerwear made of woven fabrics',
            'Manufacture of paints, varnishes and similar coatings, printing ink and mastics': 'Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
            'Manufacture of paper or paperboard in bulk, in rolls or sheets ': 'Manufacture of paper or paperboard in bulk, in rolls or sheets ',
            'Manufacture of parts and accessories for motor vehicles, including engines, bodies (coachwork) and trailers ': 'Manufacture of parts and accessories for motor vehicles, including engines, bodies (coachwork) and trailers ',
            'Manufacture of parts of footwear': 'Manufacture of parts of footwear',
            'Manufacture of pesticides and other agro-chemical products ': 'Manufacture of pesticides and other agro-chemical products ',
            'Manufacture of pharmaceuticals, medicinal chemicals and botanical products': 'Manufacture of pharmaceuticals, medicinal chemicals and botanical products',
            'Manufacture of plastic toys': 'Manufacture of plastic toys',
            'Manufacture of plastics in primary forms and of synthetic rubber ': 'Manufacture of plastics in primary forms and of synthetic rubber ',
            'Manufacture of printed circuits': 'Manufacture of printed circuits',
            'Manufacture of pulp ': 'Manufacture of pulp ',
            'Manufacture of radio-broadcasting receivers and sound recording/ playing apparatus, including telephone answering machines, CD players, cassette-type recorders and cassette players, record players, and related products like amplifiers, loudspeakers, micro': 'Manufacture of radio-broadcasting receivers and sound recording/ playing apparatus, including telephone answering machines, CD players, cassette-type recorders and cassette players, record players, and related products like amplifiers, loudspeakers, micro',
            'Manufacture of railway and tramway locomotives and rolling stock': 'Manufacture of railway and tramway locomotives and rolling stock',
            'Manufacture of refined petroleum products ': 'Manufacture of refined petroleum products ',
            'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres ': 'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres ',
            'Manufacture of semi-manufactures of plastic products such as sheets, film, foil etc.': 'Manufacture of semi-manufactures of plastic products such as sheets, film, foil etc.',
            'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations': 'Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
            'Manufacture of soft drinks; production of mineral waters': 'Manufacture of soft drinks; production of mineral waters',
            'Manufacture of sports goods': 'Manufacture of sports goods',
            'Manufacture of structural clay and ceramic products including ceramic building materials such as bricks, flooring blocks, roofing tiles and chimney-pots': 'Manufacture of structural clay and ceramic products including ceramic building materials such as bricks, flooring blocks, roofing tiles and chimney-pots',
            'Manufacture of sugar': 'Manufacture of sugar',
            'Manufacture of television and radio receivers, sound or video recording or reproducing apparatus, and associated goods, n.e.c., including manufacture of specialized parts': 'Manufacture of television and radio receivers, sound or video recording or reproducing apparatus, and associated goods, n.e.c., including manufacture of specialized parts',
            'Manufacture of television and radio transmitters ': 'Manufacture of television and radio transmitters ',
            'Manufacture of television sets': 'Manufacture of television sets',
            'Manufacture of textiles n.e.c.': 'Manufacture of textiles n.e.c.',
            'Manufacture of tobacco products ': 'Manufacture of tobacco products ',
            'Manufacture of toys made of textile materials e.g. stuffed toys': 'Manufacture of toys made of textile materials e.g. stuffed toys',
            'Manufacture of underwear, nightwear and swimwear ': 'Manufacture of underwear, nightwear and swimwear ',
            'Manufacture of unrecorded discs and tapes': 'Manufacture of unrecorded discs and tapes',
            'Manufacture of vegetable and animal oils and fats': 'Manufacture of vegetable and animal oils and fats',
            'Manufacture of veneer sheets, plywood, particle board and fibreboard ': 'Manufacture of veneer sheets, plywood, particle board and fibreboard ',
            'Manufacture of video recording, playing or reproducing apparatus, including camcorder, digital cameras, video cassette players and VCD and DVD players': 'Manufacture of video recording, playing or reproducing apparatus, including camcorder, digital cameras, video cassette players and VCD and DVD players',
            'Manufacture of watch and clock parts such as movements, springs, dials, plates, bridges, watch straps and other parts': 'Manufacture of watch and clock parts such as movements, springs, dials, plates, bridges, watch straps and other parts',
            'Manufacture of watches and clocks': 'Manufacture of watches and clocks',
            'Manufacture of wearing apparel and clothing accessories made of furskins or hides': 'Manufacture of wearing apparel and clothing accessories made of furskins or hides',
            'Manufacture of wearing apparel made of leather or composition leather': 'Manufacture of wearing apparel made of leather or composition leather',
            'Manufacture of wearing apparel, except fur apparel, n.e.c.': 'Manufacture of wearing apparel, except fur apparel, n.e.c.',
            'Manufacture of wooden containers': 'Manufacture of wooden containers',
            'Manufacture of woven fabrics- cotton': 'Manufacture of woven fabrics- cotton',
            'Manufacture of woven fabrics- others': 'Manufacture of woven fabrics- others',
            'Manufacture of yarn or thread': 'Manufacture of yarn or thread',
            'Market research and public opinion polling': 'Market research and public opinion polling',
            'Medical practice including clinics (excluding Chinese herbalists)': 'Medical practice including clinics (excluding Chinese herbalists)',
            'Medical, Dental and X-Ray Laboratories': 'Medical, Dental and X-Ray Laboratories',
            'Mining of coal and lignite; extraction of peat': 'Mining of coal and lignite; extraction of peat',
            'Mining of other metal ores': 'Mining of other metal ores',
            'Mining of uranium and thorium ores': 'Mining of uranium and thorium ores',
            'Mobile network operators including paging service operators': 'Mobile network operators including paging service operators',
            'Money services businesses (SCC)': 'Money services businesses (SCC)',
            'Mortgage lenders': 'Mortgage lenders',
            'Motion picture and video production and distribution': 'Motion picture and video production and distribution',
            'Motion picture projection ': 'Motion picture projection ',
            'Movers': 'Movers',
            'Museums activities and preservation of historical sites and buildings': 'Museums activities and preservation of historical sites and buildings',
            'National post activities': 'National post activities',
            'News agency activities e.g. furnishing news pictures and features to the media and activities of journalists': 'News agency activities e.g. furnishing news pictures and features to the media and activities of journalists',
            'Nominee companies': 'Nominee companies',
            'Non-Chinese Restaurants': 'Non-Chinese Restaurants',
            'Non-life insurance': 'Non-life insurance',
            'Non-scheduled air transport, including transport via space': 'Non-scheduled air transport, including transport via space',
            'Operation of slaughterhouses ': 'Operation of slaughterhouses ',
            'Operation of terminal facilities ': 'Operation of terminal facilities ',
            'Operation of transport infrastructure e.g. roads, car parks, etc.': 'Operation of transport infrastructure e.g. roads, car parks, etc.',
            'Operation of web search portals and internet entertainment sites': 'Operation of web search portals and internet entertainment sites',
            'Other business activities n.e.c.': 'Other business activities n.e.c.',
            'Other credit granting by institutions not involved in monetary intermediation': 'Other credit granting by institutions not involved in monetary intermediation',
            'Other education': 'Other education',
            'Other entertainment and recreational activities n.e.c. ': 'Other entertainment and recreational activities n.e.c. ',
            'Other financial intermediation n.e.c., except insurance and pension funding ': 'Other financial intermediation n.e.c., except insurance and pension funding ',
            'Other human health activities n.e.c.': 'Other human health activities n.e.c.',
            'Other manufacturing n.e.c.': 'Other manufacturing n.e.c.',
            'Other membership organizations n.e.c.': 'Other membership organizations n.e.c.',
            'Other mining and quarrying n.e.c.': 'Other mining and quarrying n.e.c.',
            'Other monetary intermediation n.e.c.': 'Other monetary intermediation n.e.c.',
            'Other non-scheduled passenger land transport ': 'Other non-scheduled passenger land transport ',
            'Other non-store retail sale, such as by door-to-door salespersons, vending machines or via internet auctions': 'Other non-store retail sale, such as by door-to-door salespersons, vending machines or via internet auctions',
            'Other publishing, including publishing of photos, postcards, posters, reproduction of works of art, etc': 'Other publishing, including publishing of photos, postcards, posters, reproduction of works of art, etc',
            'Other retail sale in non-specialized stores': 'Other retail sale in non-specialized stores',
            'Other retail sale in specialized stores, n.e.c.': 'Other retail sale in specialized stores, n.e.c.',
            'Other scheduled passenger land transport': 'Other scheduled passenger land transport',
            'Other service activities n.e.c. ': 'Other service activities n.e.c. ',
            'Other software consultancy and supply': 'Other software consultancy and supply',
            'Other supporting transport activities n.e.c.': 'Other supporting transport activities n.e.c.',
            'Other transport agencies n.e.c.': 'Other transport agencies n.e.c.',
            'Other wholesale/ trading n.e.c.': 'Other wholesale/ trading n.e.c.',
            'Packaging activities': 'Packaging activities',
            'Pension funding': 'Pension funding',
            'Photographic activities ': 'Photographic activities ',
            'Physiotherapy ': 'Physiotherapy ',
            'Political organizations': 'Political organizations',
            'Preservation and preparation of meat and meat products ': 'Preservation and preparation of meat and meat products ',
            'Primary and pre-primary education': 'Primary and pre-primary education',
            'Printing for others on a fee or contract basis': 'Printing for others on a fee or contract basis',
            'Processing and preserving of fish and fish products': 'Processing and preserving of fish and fish products',
            'Processing and preserving of fruit and vegetables': 'Processing and preserving of fruit and vegetables',
            'Processing of nuclear fuel': 'Processing of nuclear fuel',
            'Production and casting of other non-ferrous metals including copper, lead, chrome, manganese, zinc, aluminium, nickel, tin or other non-ferrous base metals and alloys of such metals': 'Production and casting of other non-ferrous metals including copper, lead, chrome, manganese, zinc, aluminium, nickel, tin or other non-ferrous base metals and alloys of such metals',
            'Production of radio and television programmes': 'Production of radio and television programmes',
            'Production, collection and distribution of electricity': 'Production, collection and distribution of electricity',
            'Production, collection and distribution of steam and hot water for heating, power and other purposes': 'Production, collection and distribution of steam and hot water for heating, power and other purposes',
            'Professional organizations': 'Professional organizations',
            'Property development- industrial': 'Property development- industrial',
            'Property development- office/ shop': 'Property development- office/ shop',
            'Property development- other': 'Property development- other',
            'Property development- residential': 'Property development- residential',
            'Property holding for rental – industrial': 'Property holding for rental – industrial',
            'Property holding for rental – office/ shop': 'Property holding for rental – office/ shop',
            'Property holding for rental – residential': 'Property holding for rental – residential',
            'Property holding for self-use': 'Property holding for self-use',
            'Publishing of books, brochures, musical books and other publications': 'Publishing of books, brochures, musical books and other publications',
            'Publishing of newspapers, journals and periodicals': 'Publishing of newspapers, journals and periodicals',
            'Publishing of records and other recorded audio media': 'Publishing of records and other recorded audio media',
            'Real estate activities on a fee or contract basis': 'Real estate activities on a fee or contract basis',
            'Real estate activities with own or leased property, n.e.c.': 'Real estate activities with own or leased property, n.e.c.',
            'Recycling of metal waste and scrap': 'Recycling of metal waste and scrap',
            'Recycling of non-metal waste and scrap': 'Recycling of non-metal waste and scrap',
            'Religious organizations': 'Religious organizations',
            'Renting of air transport equipment without operator': 'Renting of air transport equipment without operator',
            'Renting of construction or demolition equipment with operator': 'Renting of construction or demolition equipment with operator',
            'Renting of land transport equipment without driver/ operator ': 'Renting of land transport equipment without driver/ operator ',
            'Renting of other machinery and equipment ': 'Renting of other machinery and equipment ',
            'Renting of personal and household goods n.e.c.': 'Renting of personal and household goods n.e.c.',
            'Renting of water transport equipment without operator': 'Renting of water transport equipment without operator',
            'Repair and alteration of personal and household goods': 'Repair and alteration of personal and household goods',
            'Reproduction of recorded media- including records, audio and video tapes and computer software- from master copies': 'Reproduction of recorded media- including records, audio and video tapes and computer software- from master copies',
            'Research and development ': 'Research and development ',
            'Restaurants, bars and canteens n.e.c.': 'Restaurants, bars and canteens n.e.c.',
            'Restricted licensed banks': 'Restricted licensed banks',
            'Retail sale by mail order via mail order houses, television, radio, telephone or internet': 'Retail sale by mail order via mail order houses, television, radio, telephone or internet',
            'Retail sale in non-specialized stores with food, beverages or tobacco predominating': 'Retail sale in non-specialized stores with food, beverages or tobacco predominating',
            'Retail sale of antiques': 'Retail sale of antiques',
            'Retail sale of articles of clothing, fur and clothing accessories': 'Retail sale of articles of clothing, fur and clothing accessories',
            'Retail sale of articles of lighting': 'Retail sale of articles of lighting',
            'Retail sale of automotive fuel': 'Retail sale of automotive fuel',
            'Retail sale of bakery products': 'Retail sale of bakery products',
            'Retail sale of books, newspapers, magazines and stationery': 'Retail sale of books, newspapers, magazines and stationery',
            'Retail sale of dried seafood': 'Retail sale of dried seafood',
            'Retail sale of flowers, plants, seeds and fertilizers ': 'Retail sale of flowers, plants, seeds and fertilizers ',
            'Retail sale of food, beverages and tobacco in specialized stores n.e.c.': 'Retail sale of food, beverages and tobacco in specialized stores n.e.c.',
            'Retail sale of footwear, leather goods and travel accessories of leather and leather substitutes': 'Retail sale of footwear, leather goods and travel accessories of leather and leather substitutes',
            'Retail sale of games and toys': 'Retail sale of games and toys',
            'Retail sale of hardware, paints and glass': 'Retail sale of hardware, paints and glass',
            'Retail sale of household appliances, radio and television equipment and other household audio/ visual equipment ': 'Retail sale of household appliances, radio and television equipment and other household audio/ visual equipment ',
            'Retail sale of household articles of textile materials, including curtains ': 'Retail sale of household articles of textile materials, including curtains ',
            'Retail sale of household furniture': 'Retail sale of household furniture',
            'Retail sale of household utensils, cutlery, crockery and kitchenware ': 'Retail sale of household utensils, cutlery, crockery and kitchenware ',
            'Retail sale of meat, fruit, vegetables, fishery (except dried seafood) and eggs': 'Retail sale of meat, fruit, vegetables, fishery (except dried seafood) and eggs',
            'Retail sale of musical instruments and scores ': 'Retail sale of musical instruments and scores ',
            'Retail sale of musical records, audio/ visual tapes, CDs and cassettes ': 'Retail sale of musical records, audio/ visual tapes, CDs and cassettes ',
            'Retail sale of office equipment, computers and non-customized software': 'Retail sale of office equipment, computers and non-customized software',
            'Retail sale of optical products': 'Retail sale of optical products',
            'Retail sale of perfumery, cosmetic and toilet articles ': 'Retail sale of perfumery, cosmetic and toilet articles ',
            'Retail sale of pet animals and food': 'Retail sale of pet animals and food',
            'Retail sale of pharmaceutical and medical goods': 'Retail sale of pharmaceutical and medical goods',
            'Retail sale of photographic and precision equipment': 'Retail sale of photographic and precision equipment',
            'Retail sale of second-hand goods in stores n.e.c.': 'Retail sale of second-hand goods in stores n.e.c.',
            'Retail sale of sports goods ': 'Retail sale of sports goods ',
            'Retail sale of sugar, chocolate, confectionery, snacks and non-alcoholic beverages': 'Retail sale of sugar, chocolate, confectionery, snacks and non-alcoholic beverages',
            'Retail sale of telecommunication equipment': 'Retail sale of telecommunication equipment',
            'Retail sale of textiles, knitting yarn, fabrics and basic materials for rug, tapestry or embroidery making': 'Retail sale of textiles, knitting yarn, fabrics and basic materials for rug, tapestry or embroidery making',
            'Retail sale of wallpaper, floor coverings, carpets and rugs': 'Retail sale of wallpaper, floor coverings, carpets and rugs',
            'Retail sale of watches, clocks and jewellery': 'Retail sale of watches, clocks and jewellery',
            'Retail sale of wine and liquors': 'Retail sale of wine and liquors',
            'Retail sale via stalls and markets': 'Retail sale via stalls and markets',
            'Sale and servicing of motor vehicles and motorcycles n.e.c.': 'Sale and servicing of motor vehicles and motorcycles n.e.c.',
            'Sale and servicing of motorcycles and related parts and accessories': 'Sale and servicing of motorcycles and related parts and accessories',
            'Sale of motor vehicle parts and accessories': 'Sale of motor vehicle parts and accessories',
            'Sale of new and used right-hand drive motor vehicles- retail': 'Sale of new and used right-hand drive motor vehicles- retail',
            'Sale of new and used right-hand drive motor vehicles- wholesale': 'Sale of new and used right-hand drive motor vehicles- wholesale',
            'Sawmilling and planing of wood': 'Sawmilling and planing of wood',
            'Scheduled air transport': 'Scheduled air transport',
            'Sea and coastal water transport ': 'Sea and coastal water transport ',
            'Securities custodians': 'Securities custodians',
            'Security dealing in futures by broker on behalf of customers': 'Security dealing in futures by broker on behalf of customers',
            'Security dealing in options by broker on behalf of customers': 'Security dealing in options by broker on behalf of customers',
            'Security dealing in shares by broker on behalf of customers': 'Security dealing in shares by broker on behalf of customers',
            'Security dealing n.e.c.': 'Security dealing n.e.c.',
            'Service activities incidental to oil and gas extraction excluding surveying, provided on a fee or contract basis': 'Service activities incidental to oil and gas extraction excluding surveying, provided on a fee or contract basis',
            'Service activities related to printing, including bookbinding and production of prepared printing plates ': 'Service activities related to printing, including bookbinding and production of prepared printing plates ',
            'Servicing of motor vehicles': 'Servicing of motor vehicles',
            'Sewage and refuse disposal, sanitation and similar activities': 'Sewage and refuse disposal, sanitation and similar activities',
            'Site preparation, including demolition of buildings, blasting, test drilling, and landfill': 'Site preparation, including demolition of buildings, blasting, test drilling, and landfill',
            'Social work activities provided by government offices': 'Social work activities provided by government offices',
            'Social work activities provided by private or charitable organizations': 'Social work activities provided by private or charitable organizations',
            'Software publishing': 'Software publishing',
            'Sporting activities, including the operation of the facilities in which various sports are performed': 'Sporting activities, including the operation of the facilities in which various sports are performed',
            'Storage and warehousing': 'Storage and warehousing',
            'Tanning and dressing of leather': 'Tanning and dressing of leather',
            'Technical and vocational secondary education': 'Technical and vocational secondary education',
            'Technical testing and analysis of all types of materials and products ': 'Technical testing and analysis of all types of materials and products ',
            'Telecommunications, n.e.c.': 'Telecommunications, n.e.c.',
            'Trade unions': 'Trade unions',
            'Trading of agricultural raw materials and live animals': 'Trading of agricultural raw materials and live animals',
            'Trading of beer, wine and liquors': 'Trading of beer, wine and liquors',
            'Trading of blank audio and video tapes and diskettes, and magnetic and optical disks': 'Trading of blank audio and video tapes and diskettes, and magnetic and optical disks',
            'Trading of clothing and accessories ': 'Trading of clothing and accessories ',
            'Trading of coffee, tea, cocoa and spices': 'Trading of coffee, tea, cocoa and spices',
            'Trading of computers, computer peripheral equipment and software': 'Trading of computers, computer peripheral equipment and software',
            'Trading of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.': 'Trading of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.',
            'Trading of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.': 'Trading of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.',
            'Trading of dried seafood': 'Trading of dried seafood',
            'Trading of edible oils and fats': 'Trading of edible oils and fats',
            'Trading of electrical material': 'Trading of electrical material',
            'Trading of electronic and telecommunications parts and equipment n.e.c.': 'Trading of electronic and telecommunications parts and equipment n.e.c.',
            'Trading of electronic valves and tubes': 'Trading of electronic valves and tubes',
            'Trading of food, beverages and tobacco n.e.c.': 'Trading of food, beverages and tobacco n.e.c.',
            'Trading of footwear': 'Trading of footwear',
            'Trading of games and toys, musical instruments, sports goods and bicycles ': 'Trading of games and toys, musical instruments, sports goods and bicycles ',
            'Trading of general purpose machinery and machine tools': 'Trading of general purpose machinery and machine tools',
            'Trading of gold and other precious metals ': 'Trading of gold and other precious metals ',
            'Trading of household ceramic ware, glassware, cutlery and kitchenware': 'Trading of household ceramic ware, glassware, cutlery and kitchenware',
            'Trading of household furniture': 'Trading of household furniture',
            'Trading of industrial chemicals, fertilizers and agrochemical products': 'Trading of industrial chemicals, fertilizers and agrochemical products',
            'Trading of leather goods (including travel accessories) and paper products': 'Trading of leather goods (including travel accessories) and paper products',
            'Trading of machinery for textile, apparel and leather production': 'Trading of machinery for textile, apparel and leather production',
            'Trading of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ': 'Trading of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ',
            'Trading of medical and precision instruments': 'Trading of medical and precision instruments',
            'Trading of metal and non-metal waste and scrap and materials for recycling': 'Trading of metal and non-metal waste and scrap and materials for recycling',
            'Trading of office furniture': 'Trading of office furniture',
            'Trading of office machinery and equipment, except computers and computer peripheral equipment': 'Trading of office machinery and equipment, except computers and computer peripheral equipment',
            'Trading of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ': 'Trading of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ',
            'Trading of other household goods, n.e.c.': 'Trading of other household goods, n.e.c.',
            'Trading of other intermediate products, waste and scrap n.e.c.': 'Trading of other intermediate products, waste and scrap n.e.c.',
            'Trading of other machinery, equipment and supplies n.e.c.': 'Trading of other machinery, equipment and supplies n.e.c.',
            'Trading of other special purpose machinery ': 'Trading of other special purpose machinery ',
            'Trading of paint and varnish': 'Trading of paint and varnish',
            'Trading of pharmaceutical and medical goods, perfumerie, cosmetics and soaps': 'Trading of pharmaceutical and medical goods, perfumerie, cosmetics and soaps',
            'Trading of photographic and optical goods': 'Trading of photographic and optical goods',
            'Trading of plastic materials in primary forms': 'Trading of plastic materials in primary forms',
            'Trading of precious stones': 'Trading of precious stones',
            'Trading of pulp and paper in bulk': 'Trading of pulp and paper in bulk',
            'Trading of rice and cereals': 'Trading of rice and cereals',
            'Trading of rubber': 'Trading of rubber',
            'Trading of sanitary equipment and sanitary installation equipment': 'Trading of sanitary equipment and sanitary installation equipment',
            'Trading of semi-conductor devices, microchips, integrated circuits and printed circuits ': 'Trading of semi-conductor devices, microchips, integrated circuits and printed circuits ',
            'Trading of solid, liquid and gaseous fuels and related products': 'Trading of solid, liquid and gaseous fuels and related products',
            'Trading of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages': 'Trading of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages',
            'Trading of telephone and communications equipment': 'Trading of telephone and communications equipment',
            'Trading of textile fibres': 'Trading of textile fibres',
            'Trading of textiles, clothing and footwear n.e.c.': 'Trading of textiles, clothing and footwear n.e.c.',
            'Trading of tobacco products': 'Trading of tobacco products',
            'Trading of tools, hardware, fittings and fixtures': 'Trading of tools, hardware, fittings and fixtures',
            'Trading of transport equipment except motor vehicles, motorcycles and bicycles': 'Trading of transport equipment except motor vehicles, motorcycles and bicycles',
            'Trading of watches, clocks and jewellery': 'Trading of watches, clocks and jewellery',
            'Trading of wood, sand, stone, clay, cement, glass and related construction materials ': 'Trading of wood, sand, stone, clay, cement, glass and related construction materials ',
            'Trading of yarn, fabrics and household linens': 'Trading of yarn, fabrics and household linens',
            'Transport via pipelines, including transport of gases, liquids, slurry and other commodities via pipelines': 'Transport via pipelines, including transport of gases, liquids, slurry and other commodities via pipelines',
            'Transport via railways': 'Transport via railways',
            'Treatment and coating of metals; general mechanical engineering on a fee or contract basis': 'Treatment and coating of metals; general mechanical engineering on a fee or contract basis',
            'Trusts/ Trustees': 'Trusts/ Trustees',
            'Veterinary activities': 'Veterinary activities',
            'Washing, and (dry-) cleaning of textile and fur products': 'Washing, and (dry-) cleaning of textile and fur products',
            'Wholesale and retail of left-hand drive motor vehicles': 'Wholesale and retail of left-hand drive motor vehicles',
            'Wholesale of agricultural raw materials and live animals': 'Wholesale of agricultural raw materials and live animals',
            'Wholesale of beer, wine and liquors': 'Wholesale of beer, wine and liquors',
            'Wholesale of blank audio and video tapes and diskettes, and magnetic and optical disks': 'Wholesale of blank audio and video tapes and diskettes, and magnetic and optical disks',
            'Wholesale of clothing and accessories ': 'Wholesale of clothing and accessories ',
            'Wholesale of coffee, tea, cocoa and spices': 'Wholesale of coffee, tea, cocoa and spices',
            'Wholesale of computers, computer peripheral equipment and software': 'Wholesale of computers, computer peripheral equipment and software',
            'Wholesale of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.': 'Wholesale of construction materials, hardware, plumbing and heating equipment and supplies n.e.c.',
            'Wholesale of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.': 'Wholesale of domestic electrical and electronic appliances e.g. refrigerators, lighting articles, radio and television equipment, etc.',
            'Wholesale of dried seafood': 'Wholesale of dried seafood',
            'Wholesale of edible oils and fats': 'Wholesale of edible oils and fats',
            'Wholesale of electrical material': 'Wholesale of electrical material',
            'Wholesale of electronic and telecommunications parts and equipment n.e.c.': 'Wholesale of electronic and telecommunications parts and equipment n.e.c.',
            'Wholesale of electronic valves and tubes': 'Wholesale of electronic valves and tubes',
            'Wholesale of food, beverages and tobacco n.e.c.': 'Wholesale of food, beverages and tobacco n.e.c.',
            'Wholesale of footwear': 'Wholesale of footwear',
            'Wholesale of games and toys, musical instruments, sports goods and bicycles ': 'Wholesale of games and toys, musical instruments, sports goods and bicycles ',
            'Wholesale of general purpose machinery and machine tools': 'Wholesale of general purpose machinery and machine tools',
            'Wholesale of gold and other precious metals ': 'Wholesale of gold and other precious metals ',
            'Wholesale of household ceramic ware, glassware, cutlery and kitchenware': 'Wholesale of household ceramic ware, glassware, cutlery and kitchenware',
            'Wholesale of household furniture': 'Wholesale of household furniture',
            'Wholesale of industrial chemicals, fertilizers and agrochemical products': 'Wholesale of industrial chemicals, fertilizers and agrochemical products',
            'Wholesale of leather goods (including travel accessories) and paper products': 'Wholesale of leather goods (including travel accessories) and paper products',
            'Wholesale of machinery for textile, apparel and leather production': 'Wholesale of machinery for textile, apparel and leather production',
            'Wholesale of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ': 'Wholesale of meat, fruit, vegetables, fishery (except dried seafood), eggs and dairy products ',
            'Wholesale of medical and precision instruments': 'Wholesale of medical and precision instruments',
            'Wholesale of metal and non-metal waste and scrap and materials for recycling': 'Wholesale of metal and non-metal waste and scrap and materials for recycling',
            'Wholesale of office furniture': 'Wholesale of office furniture',
            'Wholesale of office machinery and equipment, except computers and computer peripheral equipment': 'Wholesale of office machinery and equipment, except computers and computer peripheral equipment',
            'Wholesale of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ': 'Wholesale of other ferrous and non-ferrous metals, including metal ores, metals in primary forms and semi-finished metal products n.e.c. ',
            'Wholesale of other household goods, n.e.c.': 'Wholesale of other household goods, n.e.c.',
            'Wholesale of other intermediate products, waste and scrap n.e.c.': 'Wholesale of other intermediate products, waste and scrap n.e.c.',
            'Wholesale of other machinery, equipment and supplies n.e.c.': 'Wholesale of other machinery, equipment and supplies n.e.c.',
            'Wholesale of other special purpose machinery': 'Wholesale of other special purpose machinery',
            'Wholesale of paint and varnish': 'Wholesale of paint and varnish',
            'Wholesale of pharmaceutical and medical goods, perfumerie, cosmetics and soaps': 'Wholesale of pharmaceutical and medical goods, perfumerie, cosmetics and soaps',
            'Wholesale of photographic and optical goods': 'Wholesale of photographic and optical goods',
            'Wholesale of plastic materials in primary forms': 'Wholesale of plastic materials in primary forms',
            'Wholesale of precious stones': 'Wholesale of precious stones',
            'Wholesale of pulp and paper in bulk': 'Wholesale of pulp and paper in bulk',
            'Wholesale of rice and cereals': 'Wholesale of rice and cereals',
            'Wholesale of rubber': 'Wholesale of rubber',
            'Wholesale of sanitary equipment and sanitary installation equipment': 'Wholesale of sanitary equipment and sanitary installation equipment',
            'Wholesale of semi-conductor devices, microchips, integrated circuits and printed circuits ': 'Wholesale of semi-conductor devices, microchips, integrated circuits and printed circuits ',
            'Wholesale of solid, liquid and gaseous fuels and related products': 'Wholesale of solid, liquid and gaseous fuels and related products',
            'Wholesale of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages': 'Wholesale of sugar, chocolate, confectionery, snacks, bakery products and non-alcoholic beverages',
            'Wholesale of telephone and communications equipment': 'Wholesale of telephone and communications equipment',
            'Wholesale of textile fibres': 'Wholesale of textile fibres',
            'Wholesale of textiles, clothing and footwear n.e.c.': 'Wholesale of textiles, clothing and footwear n.e.c.',
            'Wholesale of tobacco products': 'Wholesale of tobacco products',
            'Wholesale of tools, hardware, fittings and fixtures': 'Wholesale of tools, hardware, fittings and fixtures',
            'Wholesale of transport equipment except motor vehicles, motorcycles and bicycles': 'Wholesale of transport equipment except motor vehicles, motorcycles and bicycles',
            'Wholesale of watches, clocks and jewellery': 'Wholesale of watches, clocks and jewellery',
            'Wholesale of wood, sand, stone, clay, cement, glass and related construction materials ': 'Wholesale of wood, sand, stone, clay, cement, glass and related construction materials ',
            'Wholesale/ Trading on a fee or contract basis, such as commission agents, commodity brokers and auctioneers ': 'Wholesale/ Trading on a fee or contract basis, such as commission agents, commodity brokers and auctioneers ',
        },

        employmentStatusList: [
            {
                value: 'SELF_EMPLOYED',
                label: 'Self-employed'
            },
            {
                value: 'FULL_TIME_EMPLOYMENT',
                label: 'Full-time employment'
            },
            {
                value: 'PART_TIME_EMPLOYMENT',
                label: 'Part-time employment'
            },
            {
                value: 'UNEMPLOYED',
                label: 'Unemployed'
            },
            {
                value: 'STUDENT',
                label: 'Student'
            },
            {
                value: 'HOMEMAKER',
                label: 'Homemaker'
            },
            {
                value: 'RETIRED',
                label: 'Retired'
            }
        ],

        residentialReasonList: [
            {value: 'A', label: "I'm a student studying in the country / region of my residential address and haven't lived there long enough to become a tax resident."},
            {value: 'B', label: "I'm working in the country / region of my residential address and haven't lived there long enough to become a tax resident."},
            {value: 'C', label: "I'm a diplomat or member of the armed forces posted to the country / region of my residential address."},
            {value: 'D', label: "I've recently moved to the country / region of my residential address and I'm not yet a tax resident."},
            {value: 'E', label: 'None of the above.'}
        ],
        correspondenceReasonList: [
            {value: 'A', label: "My correspondence address is the address of my advisor / accountant / lawyer."},
            {value: 'B', label: "My correspondence address is my relative's address."},
            {value: 'C', label: "My correspondence address is my work / employer's address."},
            {value: 'D', label: "My correspondence address is my holiday home."},
            {value: 'E', label: 'None of the above.'}
        ],
    }
};
